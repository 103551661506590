import React from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  children?: React.ReactNode;
}

const overlayStyle = {
  backdropFilter: "blur(2px)"
};

const Modal = ({ children }: ModalProps) => {
  return ReactDOM.createPortal(
    <div className="w-full h-full bg-black0 fixed top-0 z-50" style={overlayStyle}>
      <div className="absolute top-1/2 left-1/2" style={{ transform: 'translate(-50%,-50%)'}}>{children}</div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
