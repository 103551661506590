
export type Message = BotMessage | UserMessage;

export class BotMessage {
  readonly type = "bot";

  original: any;
  constructor(message: any) {
    this.original = message;
  }
}

export class UserMessage {
  readonly type = "user";
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}
