import React, { useState, useEffect } from "react";
import "isomorphic-fetch";
import config from "config";
import { TeamMateModel } from "models";
import { Avatar } from "components";
import moment from "moment";
import "./MyTeam.css";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { refreshData } from "services/Helpers.service";
import NoContent from "../../NoContent/NoContent";

// Test data
import { myTeam, desensitisedMyTeam } from "../../../testData/myTeamTestData";

interface MyTeamProps {
  emailString: string;
  demo?: boolean;
}

export const MyTeamWidget = (props: MyTeamProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [officeData, setOfficeData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [hideOffice, setHideOffice] = useState(false);
  const [offices, setOffices] = useState([]);
  const [officeDataToShow, setOfficeDataToShow] = useState([]);

  const fetchMyTeam = (emailString: string, demo: boolean) => {
    setLoading(true);

    if (demo) {
      setData(myTeam);
      setLoading(false);
      return;
    }

    fetch(`${config.SophieApiUrl}/Api/MyTeam?emailString=${emailString}`)
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((data) => {
        setLoading(false);
        setData(data);
      });
  };

  const fetchOffices = () => {
    fetch(`${config.SophieApiUrl}/Api/MyTeam/offices`)
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((data) => {
        if (data) {
          setOffices(data);
        }
      });
  };

  useEffect(() => {
    const officeData = [];
    offices.forEach((office) => {
      fetch(`${config.SophieApiUrl}/Api/MyTeam/office?location=${office}`)
        .then((response) => (response.status === 200 ? response.json() : null))
        .then((data) => {
          const loadedOfficeData = { location: office, data: data };
          officeData.push(loadedOfficeData);
          if (officeData.length === offices.length) {
            if (props.demo) {
              setOfficeData(desensitisedMyTeam(officeData));
              return;
            }

            setOfficeData(officeData);
          }
        });
    });
  }, [offices, props.demo]);

  const locationClicked = (event) => {
    const location = event.target.id;
    if (!location || selectedLocation === location) {
      setHideOffice(!hideOffice);
    } else {
      setSelectedLocation(location);
      setHideOffice(false);
      const selectedOffice = officeData.find((x) => x.location === location);
      setOfficeDataToShow(selectedOffice.data);
    }
  };

  const renderMyTeamTable = (data: TeamMateModel[]) => {
    if (!data) {
      return <div>Error loading My Team data</div>;
    }
    if (data.length === 0) {
      return <NoContent>No team mates added</NoContent>;
    } else {
      return (
        <div className="pb-6 mx-auto overflow-auto text-xl">
          <div className="flex w-full sm:hidden team-header py-4">
            <div className="flex w-3/12">
              <div className="w-3/12 sm:w-2/12"></div>
              <div className="w-9/12 sm:w-10/12 text-left font-bold">Name</div>
            </div>
            <div className="w-2/12 text-left font-bold">Project</div>
            <div className="w-2/12 text-left font-bold">Location</div>
            <div className="w-2/12 text-left font-bold">
              Estimated <br /> Arrival
            </div>
            <div className="w-2/12 text-left font-bold">
              Next <br /> Available
            </div>
            <div className="w-1/12 text-left font-bold">
              Daily <br /> Scrum
            </div>
          </div>
          {data.map((teamMate, index) => (
            <div key={index} className={`flex sm:block w-full team-row`}>
              <div className="flex sm:w-full w-3/12">
                <div className="w-3/12 py-3 sm:w-2/12 pl-4">
                  <Avatar
                    url={teamMate.avatarUrl}
                    name={teamMate.name}
                    className={"w-10 sm:h-10"}
                  />
                </div>
                <div
                  className={"w-9/12 sm:w-10/12 text-left my-auto sm:font-bold"}
                >
                  {teamMate.name}
                  <div className="hidden sm:inline-block my-auto pl-2">
                    <div
                      className={`status-square ${
                        teamMate.project === "Internal"
                          ? "status-internal"
                          : teamMate.project === "Leave"
                          ? "status-leave"
                          : "status-booked"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="w-2/12 text-left my-auto pr-2 sm:w-full">
                <label className="hidden sm:inline-block pl-4 pr-2 font-bold">
                  Project:
                </label>
                {teamMate.project ? teamMate.project : "-"}
              </div>
              <div className="w-2/12 text-left my-auto pr-2 sm:w-full">
                <label className="hidden sm:inline-block pl-4 pr-2 font-bold">
                  Location:
                </label>
                {teamMate.location ? teamMate.location : "-"}
              </div>
              <div className="w-2/12 text-left my-auto pr-2 sm:w-full">
                <label className="hidden sm:inline-block pl-4 pr-2 font-bold">
                  Estimated Arrival:
                </label>
                {teamMate.estimatedArrival
                  ? moment(teamMate.estimatedArrival).local().format("h:mm a")
                  : "-"}
              </div>
              <div className="w-2/12 text-left my-auto pr-2 sm:w-full">
                <label className="hidden sm:inline-block pl-4 pr-2 font-bold">
                  Next Available:
                </label>
                {getAvailableDate(teamMate.nextAvailable)}
              </div>
              <div className="w-1/12 text-left my-auto pr-2 sm:w-full">
                <div className="flex">
                  <label className="hidden sm:inline-block pl-4 pr-2 font-bold">
                    Daily Scrum:
                  </label>
                  <div className="w-1/2 sm:inline-block">
                    {teamMate.dailyScrum
                    ? moment(teamMate.dailyScrum).local().format("h:mm a")
                    : "-"}
                  </div>
                  <div className="sm:hidden w-1/2 my-auto">
                    <div
                      className={`status-square ${
                        teamMate.project === "Internal"
                          ? "status-internal"
                          : teamMate.project === "Leave"
                          ? "status-leave"
                          : "status-booked"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex pr-4 justify-end mt-2 sm:pr-0 sm:justify-center">
            <div className="flex">
              Client work
              <div className="status-square status-booked my-auto ml-2"></div>
            </div>
            <div className="px-8 sm:px-2"></div>
            <div className="flex">
              Internal work
              <div className="status-square status-internal my-auto ml-2"></div>
            </div>
            <div className="px-8 sm:px-2"></div>
            <div className="flex">
              Leave
              <div className="status-square status-leave my-auto ml-2"></div>
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    fetchMyTeam(props.emailString, props.demo);
    fetchOffices();
    refreshData(() => fetchMyTeam(props.emailString, props.demo));
  }, [props.emailString, props.demo]);

  const getAvailableDate = (nextAvailable) => {
    const momentFormats = {
      nextDay: "[Tomorrow]",
      nextWeek: "ddd, DD MMM",
      sameElse: "ddd, DD MMM",
      sameDay: "[Today]",
    };

    const nextAvailableLocal = moment(nextAvailable).local();
    const preText =
      nextAvailableLocal.diff(moment(), "days") >= 6 &&
      nextAvailableLocal.diff(moment(), "days") < 13
        ? "Next week "
        : "";
    return preText + nextAvailableLocal.calendar(null, momentFormats);
  };

  return (
    <div>
      <div>
        {loading && <p className="pt-2">Loading...</p>}
        {!loading && renderMyTeamTable(data)}
      </div>
      <div className="btnContainer mb-6">
        <div className="flex flex-wrap justify-center sm:block">
          {offices.length > 0 &&
            offices.map((office, index) => {
              const loadedOffice = officeData.find(
                (x) => x.location === office
              );
              return (
                loadedOffice && (
                  <div
                    key={index}
                    className="locationBtn flex justify-between mx-16 lg:mx-10 md:mx-2 my-2 text-xl"
                    onClick={locationClicked}
                    id={loadedOffice.location}
                  >
                    {loadedOffice.location} (
                    {loadedOffice.data ? loadedOffice.data.length : 0})
                    {selectedLocation === loadedOffice.location && !hideOffice ?
                      <span className="my-auto">
                        <MdKeyboardArrowUp />
                      </span>
                      :
                      <span className="my-auto">
                        <MdKeyboardArrowDown />
                      </span>
                    }
                  </div>
                )
              );
            })}
        </div>
      </div>
      {officeDataToShow?.length > 0 && (
        <div>{!hideOffice && renderMyTeamTable(officeDataToShow)}</div>
      )}
    </div>
  );
};
