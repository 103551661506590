import React from "react";

interface AvatarProps {
  url: string;
  name: string;
  className?: string;
}

export const Avatar = (
  props: AvatarProps & React.HTMLAttributes<HTMLElement>
) => {
  const { url, name, className, ...rest } = props;

  const initials = name
    ? name
        .split(" ")
        .map(r => r[0])
        .join("")
    : "";
  return (
    <>
      {url && (
        <img src={url} alt="avatar" className={`${className} h-auto AvatarCircle`} {...rest} />
      )}
      {!url && (
        <div className={`${className} h-10 AvatarInitials`} {...rest}>
          {initials}
        </div>
      )}
    </>
  );
};