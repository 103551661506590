import React from "react";
import "./HolidaysWidget.css";
import NoContent from "../../NoContent/NoContent";

import moment from "moment-timezone";

import config from "config";

interface HolidaysState {
  holidays: HolidaysInfo[];
}

type HolidaysProps = Record<string, never>

interface HolidaysInfo {
  state: string;
  date: Date;
  name: string;
}

const Holiday = (props): any => {
  const { name, date, state } = props;

  return (
    <li className="text-xl">
      <strong>
        {name} ({state}) -
      </strong>{" "}
      {moment(date).fromNow()}
    </li>
  );
};

const Holidays = (props): any => {
  const { holidays } = props;

  return (
    <div className="text-2xl">
      {holidays.length > 0 ? <div>
        <h2>Upcoming holidays</h2>
        <ul className="holiday-list">
          {holidays.map((h, index) => (
            <Holiday key={index} state={h.state} date={h.date} name={h.name} />
          ))}
        </ul>
      </div>
        :
        <NoContent>
          <span>No upcoming holidays found</span>
        </NoContent>
      }
    </div>
  );
};

export class HolidaysWidget extends React.Component<
  HolidaysProps,
  HolidaysState
> {
  public componentDidMount() {
    fetch(`${config.SophieApiUrl}/api/PublicHoliday?`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          const holidays = data
            .map(d => {
              return { state: d.state, name: d.name, date: new Date(d.date) };
            })
            .sort((a, b) => a.date - b.date)
            .splice(0, 6);

          this.setState({ holidays });
        }
      });
  }

  public render() {
    // const { currentWeather } = this.state;

    return (
      <div className="holidays-widget">
        {this.state && this.state.holidays && (
          <Holidays holidays={this.state.holidays} />
        )}
      </div>
    );
  }
}
