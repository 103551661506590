import React from "react";
import NoContent from "../../NoContent/NoContent.jsx"

export const FrameWidget = props => {

  const isValidUrl = (urlString: string) => {
    try { 
      return Boolean(new URL(urlString)); 
    }
    catch(e){ 
      return false; 
    }
  } 

  return (
    <>
      {isValidUrl(props.src) ?
        <iframe width={props.width} height={props.height} src={props.src} />
      : 
        <NoContent>
          <span>{ props.src ? "No content found" : "iFrame settings not configured. Navigate to Edit User | Screen layout & widgets | IFrame"}</span>
        </NoContent>
      }
    </>
  );
};
