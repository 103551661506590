import moment from 'moment';

export const users = [
  "Bob Northwind",
  "John Doe",
  "Jane Smith",
  "Sophie Belle"
];

// Function to handle creating a date object
export const addDays = (days: number, hours: number, minutes: number) => {
  const result = new Date();

  result.setDate(result.getDate() + days);

  if (typeof hours === 'number') {
    result.setHours(hours);
  }

  if (typeof minutes === 'number') {
    result.setMinutes(minutes);
  }

  return result;
};

export const processTime = (time) => {
  return (
    time.hours().toString() +
    ":" +
    (time.minutes() === 0 ? "00" : time.minutes().toString())
  );
};

export const range = (start: number, end: number) => {
  if (start === end) {
    return [start];
  }

  return [start, ...range(start + 1, end)];
};

export const createTimeRange = (time_range: [number, number], date: Date) => {
  const [start, end] = time_range;

  return range(start, end).map(x => processTime(moment(addDays(0, date.getHours() + x, 0))))
}

