import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { TenantUser } from "models";
import { UserCard } from "./components";
import "styles/tailwind.css";
import config from "config";
import PolyBackground from "assets/images/polygonbg2.png";
import { ReactComponent as HomeIcon } from "assets/images/home-ico.svg";
import { ReactComponent as NewUserIcon } from "assets/images/newuser-ico.svg";

interface UsersParams {
  tenantId: string;
}

const TenantUsers = () => {
  const params = useParams<keyof UsersParams>();
  const { tenantId } = params;
  const navigate = useNavigate();
  const [users, setUsers] = useState<TenantUser[]>();

  useEffect(() => {
    refresh(tenantId);
  }, [tenantId]);

  const refresh = (tenantId) => {
    fetch(`${config.SophieApiUrl}/api/Tenant/${tenantId}/users`)
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          setUsers(result);
        }
      });
  };

  const deleteUser = (userId) => {
    fetch(`${config.SophieApiUrl}/api/Tenant/${tenantId}/users/${userId}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      refresh(tenantId);
      // TODO: Handler failure.
    });
  };

  return (
    <div className="w-full bg-grey1 h-full">
      <div
        style={{ backgroundImage: `url(${PolyBackground})` }}
        className="w-full h-full flex flex-col items-stretch bg-cover"
      >
        <div className="flex-none justify-between items-center flex shadow-0 py-2 px-5 ">
          <div className="flex text-18 font-thin text-black items-center">
            <a href={`/sophie/${tenantId}`}>
              <HomeIcon className="mr-1 h-5" />
              Home
            </a>
          </div>
          <p className="text-24 font-thin text-black">
            Active Users
          </p>
          <div className="flex">
            <button
              className="IconTextBtn DarkBtn mr-4 focus:outline-none"
              onClick={() =>
                navigate(
                  `/${tenantId}/profile/new`
                )
              }
            >
              <NewUserIcon className="mr-2 h-5" />
              add new user
            </button>
          </div>
        </div>
        <div className="UserList w-full flex-grow mt-1">
          <div className="w-auto flex flex-wrap mx-5 justify-around">
            {users &&
              users.map((u) => (
                <UserCard
                  key={u.id}
                  user={u}
                  deleteUser={deleteUser}
                  editUser={(id) =>
                    navigate(
                      `/${tenantId}/profile/${id}?source=profiles`
                    )
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantUsers;
