import React from "react";
import { VoiceRecognitionWidget } from "..";
import { Message, BotMessage, UserMessage } from "../Bot/BotMessages";
import "../Bot/BotWidget.css";

type VisitorGreetingProps  = Record<string, never>

interface VisitorGreetingState {
  isListening: boolean;
  lastMessage: string;

  conversation: Message[];
}

export class VisitorGreeting extends React.Component<
  VisitorGreetingProps,
  VisitorGreetingState
> {
  constructor(props) {
    super(props);

    const messages = [
      { type: 0, speech: `Welcome to SSW.` },
      {
        type: 0,
        speech:
          "I am Sophie, I can find anyone in the office for you. Who are you here to see?"
      }
    ];

    const initialConversation = messages.map(m => new BotMessage(m));

    this.state = {
      isListening: false,
      conversation: initialConversation,
      lastMessage: ""
    };

    // This binding is necessary to make `this` work in the callback
    this.renderVoiceWidget = this.renderVoiceWidget.bind(this);
    this.processMessage = this.processMessage.bind(this);
    this.toggleListening = this.toggleListening.bind(this);
  }

  askWhom(targetPerson: string, conversation: Message[]): Message[] {
    const resp1 = {
      type: 0,
      speech: `It looks like ${targetPerson} is currently in the office.`
    };
    const resp2 = { type: 0, speech: `What is your name please?` };
    conversation.push(new BotMessage(resp1));
    conversation.push(new BotMessage(resp2));
    return conversation;
  }

  fetchingTarget(
    targetName: string,
    guestName: string,
    conversation: Message[]
  ): Message[] {
    const resp1 = {
      type: 0,
      speech: `Thanks ${guestName}, please take a seat. ${targetName} will be with you shortly`
    };
    conversation.push(new BotMessage(resp1));
    return conversation;
  }
  processMessage(message: string, messageEnd: boolean) {
    let conversation = [...this.state.conversation];
    let lastMessage = message;
    let listening = this.state.isListening;
    if (messageEnd) {
      conversation.push(new UserMessage(message.trim()));
      const userMessages = conversation
        .filter(m => m.type === "user")
        .map(m => m as UserMessage);
      if (userMessages.length === 1) {
        // anthony: get first name in case user say something like "I'm here to see Anthony"
        conversation = this.askWhom(this.getFirstName(message), conversation);
      } else if (userMessages.length === 2) {
        const targetName = userMessages[0].message.split(" ")[0];
        const guestName = userMessages[1].message.split(" ")[0];
        conversation = this.fetchingTarget(targetName, guestName, conversation);
        listening = false;
      }
      lastMessage = "";
    }

    const state = {
      ...this.state,
      lastMessage: lastMessage,
      conversation: conversation,
      isListening: listening
    };
    this.setState(state);
  }

  getFirstName(message) {
    // anthony:
    const words = message.split(" ");
    if (words.length === 1) {
      // user only supplied 1 name (e/g Anthony)
      return this.titleCase(words[0]);
    } else {
      // get first Title case word (Anthony Nguyen)
      const names = words.split(" ").filter(
        word =>
          /[A-Z]|[\u0080-\u024F]/.test(word[0]) &&
          word.toLowerCase() !== "i'm" && // I'm here to see Ulysses
          word.toLowerCase() !== "i" && // I am here to see Ulysses
          word[0] === word[0].toUpperCase()
      );
      if (names.length > 0) {
        return this.titleCase(names[0]);
      }
      return this.titleCase(message.split(" ")[0]);
    }
  }
  titleCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  toggleListening() {
    this.setState({ ...this.state, isListening: true });
  }

  renderMessage(message: Message, index: number) {
    return (
      <li key={index}>
        <div
          className={
            message.type === "user" ? "user-container" : "bot-container"
          }
        >
          <div className="message">
            {" "}
            {message.type === "user"
              ? message.message
              : message.original.speech}
          </div>
        </div>
      </li>
    );
  }

  renderVoiceWidget() {
    return (
      <VoiceRecognitionWidget
        onMessageEnd={msg => this.processMessage(msg, true)}
        onIntermediateMessage={msg => this.processMessage(msg, false)}
      >
        <div className="bot-panel">
          <ul>
            {" "}
            {this.state.conversation.map((message, index) =>
              this.renderMessage(message, index)
            )}
            {this.state.lastMessage.length > 0 &&
              this.renderMessage(
                { type: "user", message: this.state.lastMessage },
                this.state.conversation.length
              )}
          </ul>
        </div>
      </VoiceRecognitionWidget>
    );
  }

  render() {
    return <div className="bot-widget">{this.renderVoiceWidget()}</div>;
  }
}
