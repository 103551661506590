import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { UserProfile, WidgetSettingsInfo } from "../../models";
import {
  FacialRecognition,
  UserDetails,
  DashboardConfig,
  UserConnections,
} from "./components";
import { 
  toastSuccess, 
  toastError 
} from "services/ToastService.service";
import config from "../../config";
import { Formik } from "formik";
import { ReactComponent as BackIcon } from "assets/images/circle-arrow-left.svg";
import { ReactComponent as SaveExit } from "assets/images/undo-alt-regular.svg";
import { ReactComponent as SaveIcon } from "assets/images/save.svg";
import { ReactComponent as CameraIcon } from "assets/images/camera-retro-light.svg";
import { ReactComponent as IdIcon } from "assets/images/id-card-light.svg";
import { ReactComponent as UserIcon } from "assets/images/user-plus-light.svg";
import { ReactComponent as TabletIcon } from "assets/images/tablet-light.svg";
import "./userProfile.css";
import UserSection from "./components/UserSection";

interface UserProfileParams {
  tenantId: string;
  userId: string; //TODO: This should be come from the AUTH Token, not from the URL?
}

const UserProfileAdmin = () => {
  const params = useParams<keyof UserProfileParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserProfile>();
  const [allWidgets, setAllWidgets] = useState<WidgetSettingsInfo[]>([]);
  const [users, setUsers] = useState(null);
  const [connections, setConnections] = useState([]);
  const [locations, setLocations] = useState([]);

  // source page that profile page was called
  const sourcePage = location.search.replace("?source=", "");

  useEffect(() => {
    if (connections.length < 0) {
      const { userId, tenantId } = params;
      fetch(
        // TODO: How do we know TenantId and LocationId should be available via the Auth token.?
        `${config.SophieApiUrl}/api/UserProfiles/${userId}?tenantId=${tenantId}`
      )
        .then((response) => (response.status === 200 ? response.json() : null))
        .then((result) => {
          const userProfile = result as UserProfile;
          setUser(userProfile);
        });
    }
  }, [connections]);

  useEffect(() => {
    const { userId, tenantId } = params;

    fetch(`${config.SophieApiUrl}/api/Tenant/${tenantId}/users`)
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          setUsers(result);
        }
      });

    fetch(
      `${config.SophieApiUrl}/api/locationInfo/allLocations?tenantId=${tenantId}`
    )
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          setLocations(result);
        }
      });

    if (userId === "new") {
      setUser({
        id: "",
        userProfileId: "",
        name: "",
        email: "",
        avatarUrl: "",
        dashboard: { widgets: [] },
        tenantId: tenantId,
        connections: [],
        defaultLocation: "",
      });
      return;
    }

    fetch(
      // TODO: How do we know TenantId and LocationId should be available via the Auth token.?
      `${config.SophieApiUrl}/api/UserProfiles/${userId}?tenantId=${tenantId}`
    )
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        const userProfile = result as UserProfile;
        setUser(userProfile);
      });
  }, [
    params.userId,
    params.tenantId,
    params,
  ]);

  useEffect(() => {
    fetch(`${config.SophieApiUrl}/api/WidgetSettings`)
      .then((response) => (response.status === 200 ? response.json() : []))
      .then((result) => setAllWidgets(result));
  }, []);

  useEffect(() => {
    reloadConnections();
  }, []);

  const reloadConnections = useCallback(() => {
    const { userId, tenantId } = params;

    fetch(
      `${config.SophieApiUrl}/api/UserProfiles/${userId}/connections`
    )
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          setConnections(result);
        }
      });
  }, [user, connections]);

  const handleSaveAllConnections = (userProfileId) => {
    if (connections) { 
      connections.map(connection => {
        if (connection.name === "" || connection.serviceType === "" || connection.accessToken === "") {
          toastError(`One or more connections could not be saved due to empty fields`, 'error-null');

          return;
        }

        fetch(
          `${config.SophieApiUrl}/api/UserProfiles/${userProfileId}/connections`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(connection),
          }
        )
          .then((response) => (response.status === 200 ? response.json() : null))
          .then((result) => {
            if (result) {
              reloadConnections();
              toastSuccess(`User connection ${result.name} was successfully saved.`, `save-${result.id}`);
            } else {
              console.log("connection update failed");
            }
          });
      })
    }
  }

  const saveUser = async (user: UserProfile) => {
    let userProfileId = user.id ? user.id : user.userProfileId;

    if (!userProfileId) {
      userProfileId = "";
      user.id = "";
    }

    handleSaveAllConnections(user.id)

    const result = await fetch(`${config.SophieApiUrl}/api/UserProfiles/${userProfileId}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          toastSuccess('Changes saved successfully', 'save-success');
          return 'success';
        } else {
          // TODO: It failed.
          toastError('Some changes failed to save', 'save-error');
          return 'error';
        }
      });

    return result
  };

  const handleButtonClick = async (type: "save" | "save_exit", values, setSubmitting) => {
    const updatedUser = { ...values };
    const result = await saveUser(updatedUser)
    setSubmitting(false);
    
    if (result === 'success' && type === "save_exit") {
      if (sourcePage === "profiles") {
        navigate(`/${params.tenantId}/profiles`);
      } else {
        navigate(`/sophie/${params.tenantId}`);
      }
    }
  }

  const userId = !user ? null : user.id ? user.id : user.userProfileId
  
  return (
    <Formik
      initialValues={{
        name: '',
        username: '',
        email: '',
        avatarUrl: '',
        ...user,
      }}
      onSubmit={() => {return;}}
      enableReinitialize
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="user-profile_container">
            <div className="user-profile_header text-white2 text-xl">
              <div className="flex flex-1 items-center justify-start">
                <button type="button" onClick={() => navigate(-1)}>
                  <div className="flex items-center">
                    <BackIcon className="mr-4 h-7" />
                    Back
                  </div>
                </button>
              </div>
              <div className="flex-1 text-3xl font-bold">User Profile</div>
              <div className="flex flex-1 items-center justify-end">
                <button
                  className="btn-red mr-4"
                  onClick={() => handleButtonClick("save_exit", values, handleSubmit)}
                >
                  <div className="flex items-center">
                    <SaveExit className="mr-4 h-5" />
                    <p>Save & Exit</p>
                  </div>
                </button>
                <button
                  className="btn-red"
                  onClick={() => handleButtonClick("save", values, handleSubmit)}
                >
                  <div className="flex items-center">
                    <SaveIcon className="mr-4 h-6" />
                    <p>Save</p>
                  </div>
                </button>
              </div>
            </div>
            <div className="user-profile_content">
              <UserSection title="User details" Icon={IdIcon} children={
                <UserDetails
                  values={values}
                  locations={locations}
                  className="user-details-header"
                />
              } />
              <UserSection title="Face recognition" Icon={CameraIcon} children={
                <FacialRecognition
                  tenantId={params.tenantId}
                  userId={userId}
                />
              } />
              <UserSection title="User connections" Icon={UserIcon} children={
                <UserConnections
                  formConnections={values.connections}
                  userProfileId={values.id}
                  onConnectionChange={(connections) =>
                    setConnections(connections)
                  }
                  connections={connections}
                />
              } />
              <UserSection title="Screen layout & widgets" Icon={TabletIcon} children={
                <DashboardConfig
                  className="user-section_dashboard"
                  widgets={allWidgets}
                  dashboard={values.dashboard}
                  currentUser={user}
                  users={users}
                />
              } />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UserProfileAdmin;
