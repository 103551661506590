import { addDays } from './testDataUtility';

// Appointment Widget
export const testAppointmentData = [
  {
    id: "0",
    isHighlight: false,
    subject: "SSW SophieHub - Daily Scrum",
    location: "Teams Call",
    start: addDays(4, 11, 45),
    end: addDays(4, 12, 0),
    body: "SophieHub Daily Scrum",
    isCancelled: false,
  },
  {
    id: "0",
    isHighlight: false,
    subject: "Bob Northwind - 2 weeks leave",
    location: "Brisbane",
    start: addDays(36, 0, 0),
    end: addDays(36, 0, 0),
    body: "Test body",
    isCancelled: false,
  },
  {
    id: "0",
    isHighlight: true,
    subject: "SSW SophieHub - Sprint Review",
    location: "Teams Call",
    start: addDays(53, 12, 15),
    end: addDays(53, 14, 0),
    body: "Test body",
    isCancelled: false,
  } 
];
