import { hooks } from 'botframework-webchat';
import { useCallback, useState } from 'react';
import { VoiceRecognitionWidget } from '../Voice/Voice';
import "./BotWidget.css";

const { useSendMessage } = hooks;

const SendBox = () => {
  const [sendBoxValue, setSendBoxValue] = useState<string>('');
  const sendMessage = useSendMessage();

  const handleInputChange = useCallback((event) => {
    setSendBoxValue(event.target.value);
  }, []);

  const handleInputEnter = useCallback((message: string, event) => {
    if (event.key === 'Enter') {
      sendMessage(message);
      setSendBoxValue('');
    }
  }, [sendMessage]);

  const handleVoiceMessage = useCallback((message: string, send: boolean) => {
    setSendBoxValue(message);

    if (send) {
      sendMessage(message);
      setSendBoxValue('');
    }
  }, [sendMessage]);

  return (
    <div className="bot-controls">
      <input
        className="bot-textfield"
        type="text"
        placeholder="Type your questions here..."
        value={sendBoxValue}
        onChange={handleInputChange}
        onKeyPress={handleInputEnter.bind(null, sendBoxValue)}
      ></input>
      <VoiceRecognitionWidget
        onMessageEnd={(msg) => handleVoiceMessage(msg, true)}
        onIntermediateMessage={(msg) => handleVoiceMessage(msg, false)}
      />
    </div>
  );
}

export default SendBox;