import React, { useState, useEffect, useCallback } from "react";
import { SettingsInfo, UserConnection } from "models";
import SettingsField from "../SettingsField/SettingsField";
import { Field, useField, useFormikContext } from "formik";
import { debounce } from "lodash-es";

import config from "config";

interface AzureDevopsWidgetSettingsProps {
  settings: SettingsInfo[];
  widgetIndex: number;
  userConnections: UserConnection[];
  userId: string;
}

const AzureDevopsWidgetSettings = ({ settings, widgetIndex, userConnections, userId }: AzureDevopsWidgetSettingsProps) => {
  const { setFieldValue } = useFormikContext();

  const [organisationIdField] = useField(
    `dashboard.widgets[${widgetIndex}].settings.organisationId`
  );
  const [connectionIdField] = useField(
    `dashboard.widgets[${widgetIndex}].settings.connectionId`
  );
  const [projectIdField] = useField(
    `dashboard.widgets[${widgetIndex}].settings.projectId`
  );

  const initialValues = { organisation: "", connection: "", projectId: "" };

  if (organisationIdField.value) {
    initialValues.organisation = organisationIdField.value;
  }
  if (connectionIdField.value) {
    initialValues.connection = connectionIdField.value;
  }
  if (projectIdField.value) {
    initialValues.projectId = projectIdField.value;
  }

  const [connection, setConnection] = useState(initialValues.connection);
  const [organisation, setOrganisation] = useState(initialValues.organisation);
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState(initialValues.projectId);

  useEffect(() => {
    if (!userId || !connection || !organisation) {
      setProjects([]);
      return;
    }

    fetch(
      // TODO: How do we know TenantId and LocationId should be available via the Auth token.?
      `${config.SophieApiUrl}/api/DevOps/projects?connectionId=${connection}&userId=${userId}&organisation=${organisation}`
    )
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        setProjects(result);
      });
  }, [connection, userId, organisation]);

  useEffect(() => {
    if (projectId) {
      fetch(
        // TODO: How do we know TenantId and LocationId should be available via the Auth token.?
        `${config.SophieApiUrl}/api/DevOps/project?userProfileId=${userId}&&connectionId=${connection}&&organisation=${organisation}&&projectId=${projectId}`
      )
        .then((response) => (response.status === 200 ? response.json() : null))
        .then((result) => {
          setFieldValue(
            `dashboard.widgets[${widgetIndex}].settings.teamId`,
            result.teamId
          );
          setFieldValue(
            `dashboard.widgets[${widgetIndex}].settings.projectName`,
            result.name
          );
        });
    }
  }, [projectId, userId, connection, organisation, widgetIndex, setFieldValue]);

  const handleConnectionChange = (event) => {
    setFieldValue(
      `dashboard.widgets[${widgetIndex}].settings.connectionId`,
      event.target.value
    );
    setConnection(event.target.value);
  };

  const handleProjectChange = (event) => {
    setFieldValue(
      `dashboard.widgets[${widgetIndex}].settings.projectId`,
      event.target.value
    );
    setProjectId(event.target.value);
  };

  //Need to handle the Org field like this, because we are tapping into
  const { onChange, ...others } = organisationIdField;

  // eslint-disable-next-line
  const orgDebounced = useCallback(
    debounce((value) => setOrganisation(value), 250),
    []
  );

  return (
    <>
      <SettingsField
        label="Heading"
        type="text"
        widgetIndex={widgetIndex}
        defaultValue={settings["Heading"]}
        name={`dashboard.widgets[${widgetIndex}].settings.heading`}
      />

      <SettingsField
        label="Icon"
        type="text"
        widgetIndex={widgetIndex}
        defaultValue={settings["icon"]}
        name={`dashboard.widgets[${widgetIndex}].settings.icon`}
      />
      <div className="flex flex-row my-6 items-center">
        <label className="text-black w-64 text-sm">Connection</label>
        <Field
          value={initialValues.connection}
          as="select"
          onChange={handleConnectionChange}
          className="border border-grey3 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option className="text-grey0" value="" label="Select connection" />
          {userConnections.map((c, index) => (
            <option value={c.id} key={index}>
              {c.name}
            </option>
          ))}
        </Field>
      </div>

      <div className="flex flex-row my-6 items-center">
        <label className="text-black w-64 text-sm">Organisation</label>
        <Field
          as="input"
          value={initialValues.organisation}
          onChange={(e) => {
            orgDebounced(e.target.value);
            onChange(e);
          }}
          {...others}
          className="border border-grey3 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        ></Field>
      </div>

      <div className="flex flex-row my-6 items-center">
        <label className="text-black w-64 text-sm">Project</label>
        <Field
          value={initialValues.projectId}
          as="select"
          className="border border-grey3 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onChange={handleProjectChange}
        >
          <option className="text-grey0" value="" label="Select project" />
          {projects &&
            projects.map((c, index) => (
              <option value={c.id} key={index}>
                {c.name}
              </option>
            ))}
        </Field>
      </div>
    </>
  );
};

export default AzureDevopsWidgetSettings;
