import React, { useEffect, useState } from "react";

import config from "config";

interface Project {
  id: string;
  name: string;
  logo: string;
  lastDateString: string;
}

interface ActiveProjectsProps {
  projects: Project[];
}

export const DevOpsActiveProjectsWidget = (props: ActiveProjectsProps) => {
  const [projects, setProjects] = useState<Project[]>([]);

  // TODO: fix server error 500 for this endpoint call
  // PBI - https://dev.azure.com/ssw/SSW.SophieHub/_workitems/edit/70065
  // useEffect(() => {
  //   fetch(`${config.SophieApiUrl}/api/devops/activeprojects`)
  //     .then((response) => (response.status === 200 ? response.json() : null))
  //     .then((data) => {
  //       setProjects(data);
  //     });
  // }, [props.projects]);

  return (
    <div className="flex flex-row pt-4 items-center w-full justify-center">
      {projects &&
        projects.map((p) => {
          return (
            <div
              key={p.id}
              className="flex flex-col bg-white2 border-grey1 w-64 h-32 border rounded-sm m-6 py-2"
            >
              <div className="flex flex-col justify-between items-end p-4 h-full">
                <div className="flex flex-row w-full">
                  <div className="project-logo mb-3 mr-3">
                    <img src={"/images/logo/" + p.logo} alt="logo" className="w-12" />
                  </div>
                  <h6 className="text-left font-bold">{p.name}</h6>
                </div>
                <small className="mt-auto italic font-thin">
                  {p.lastDateString}
                </small>
              </div>
            </div>
          );
        })}
    </div>
  );
};
