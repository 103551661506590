import { useEffect, useState } from 'react';
import { Components, createDirectLine } from 'botframework-webchat';
import config from "config";
import SendBox from './SendBox';

const styleOptions = {
  accent: "#333",
  rootHeight: '500px',
  fontSizeSmall: '1rem',

  // Bubble
  bubbleBorderRadius: 10,
  bubbleBorderColor: '#c1c1c1',
  bubbleBackground: '#f7f9f9',
  bubbleTextColor: '#666',
  bubbleFromUserBorderRadius: 10,
  bubbleFromUserBackground: '#666',
  bubbleFromUserTextColor: '#fff',

  // Sendbox
  hideSendBox: true,

  // Adaptive Card
  cardPushButtonBackgroundColor: "#CC4141",
};

const adaptiveCardsHostConfig = {
  "hostCapabilities": {
    "capabilities": null
  },
  "choiceSetInputValueSeparator": ",",
  "supportsInteractivity": true,
  "fontTypes": {
    "default": {
      "fontFamily": "Calibri, sans-serif",
      "fontSizes": {
        "small": 14,
        "default": 16,
        "medium": 18,
        "large": 21,
        "extraLarge": 26
      },
      "fontWeights": {
        "lighter": 200,
        "default": 400,
        "bolder": 600
      }
    },
    "monospace": {
      "fontFamily": "'Courier New', Courier, monospace",
      "fontSizes": {
        "small": 14,
        "default": 16,
        "medium": 18,
        "large": 21,
        "extraLarge": 26
      },
      "fontWeights": {
        "lighter": 200,
        "default": 400,
        "bolder": 600
      }
    }
  },
  "spacing": {
    "small": 3,
    "default": 8,
    "medium": 20,
    "large": 30,
    "extraLarge": 40,
    "padding": 10
  },
  "separator": {
    "lineThickness": 1,
    "lineColor": "#EEEEEE"
  },
  "imageSizes": {
    "small": 40,
    "medium": 80,
    "large": 160
  },
  "containerStyles": {
    "default": {
      "foregroundColors": {
        "default": {
          "default": "#666666",
          "subtle": "#767676",
        },
        "dark": {
          "default": "#000000",
          "subtle": "#66000000",
        },
        "light": {
          "default": "#FFFFFF",
          "subtle": "#33000000",
        },
        "accent": {
          "default": "#CC4141",
          "subtle": "#CC4141",
        },
        "good": {
          "default": "#54a254",
          "subtle": "#DD54a254",
        },
        "warning": {
          "default": "#c3ab23",
          "subtle": "#DDc3ab23",
        },
        "attention": {
          "default": "#FF0000",
          "subtle": "#DDFF0000",
        }
      },
      "backgroundColor": "#f7f9f9"
    },
    "emphasis": {
      "foregroundColors": {
        "default": {
          "default": "#000000",
          "subtle": "#767676",
        },
        "dark": {
          "default": "#000000",
          "subtle": "#66000000",
        },
        "light": {
          "default": "#FFFFFF",
          "subtle": "#33000000",
        },
        "accent": {
          "default": "#2E89FC",
          "subtle": "#882E89FC",
        },
        "good": {
          "default": "#54a254",
          "subtle": "#DD54a254",
        },
        "warning": {
          "default": "#c3ab23",
          "subtle": "#DDc3ab23",
        },
        "attention": {
          "default": "#FF0000",
          "subtle": "#DDFF0000",
        }
      },
      "backgroundColor": "#F0F0F0"
    }
  },
  "actions": {
    "maxActions": 100,
    "spacing": "Default",
    "buttonSpacing": 8,
    "showCard": {
      "actionMode": "Inline",
      "inlineTopMargin": 8
    },
    "preExpandSingleShowCardAction": false,
    "actionsOrientation": "vertical",
    "actionAlignment": "Stretch",
    "wrap": true
  },
  "adaptiveCard": {
    "allowCustomStyle": false
  },
  "imageSet": {
    "maxImageHeight": 100
  },
  "media": {
    "allowInlinePlayback": true
  },
  "factSet": {
    "title": {
      "size": "Default",
      "color": "Default",
      "isSubtle": false,
      "weight": "Bolder",
      "wrap": true
    },
    "value": {
      "size": "Default",
      "color": "Default",
      "isSubtle": false,
      "weight": "Default",
      "wrap": true
    },
    "spacing": 8
  },
  "cssClassNamePrefix": null,
  "_legacyFontType": {
    "fontFamily": "Segoe UI,Segoe,Segoe WP,Helvetica Neue,Helvetica,sans-serif",
    "fontSizes": {
      "small": 14,
      "default": 16,
      "medium": 18,
      "large": 21,
      "extraLarge": 26
    },
    "fontWeights": {
      "lighter": 200,
      "default": 400,
      "bolder": 600
    }
  }
};

async function getDirectLineToken() {
  const res = await fetch(`${config.SophieApiUrl}/api/authentication`, { method: 'POST' });
  const { token, userId } = await res.json();

  return { token, userId };
}

// TODO: use name or other auth info to authenticate current user with SophieBot
// PBI: https://dev.azure.com/ssw/SSW.SophieHub/_workitems/edit/70137
export const BotWidget = ({ name }: { name: string }) => {
  const [directLine, setDirectLine] = useState<any>();
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    if (!directLine) {
      // We will load DirectLineJS asynchronously on first render.
      getDirectLineToken().then(({ token, userId }) => {
        setDirectLine(createDirectLine({ token }));
        setUserId(userId);
      });
    }
  }, []);

  return directLine ? (
    <Components.Composer
      directLine={directLine}
      userID={userId}
      styleOptions={styleOptions}
      adaptiveCardsHostConfig={adaptiveCardsHostConfig}
    >
      {
        <>
          <Components.BasicWebChat />
          <SendBox />
        </> as any
      }
    </Components.Composer>
  ) : null;
};