import { createTimeRange } from './testDataUtility';

const currentDate = new Date()

const testDurationResultsData = {
  x: createTimeRange([-8, 1], currentDate),
  y: [160, 180, 257, 242, 363, 376, 275, 197, 254, 337, 221]
} 

export const testDurationSeriesData = [
  {
    id: "Usage",
    color: "red",
    data: testDurationResultsData,
  },
];

const testCallCountResultsData = {
  x: createTimeRange([-9, 0], currentDate),
  y: [1, 3, 0, 1, 4, 2, 1, 2, 0, 1] 
} 

export const testCallCountSeriesData =[
  {
    id: "Usage",
    color: "red",
    data: testCallCountResultsData,
  },
];
