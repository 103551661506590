import React, { useState } from "react";
import { LocationInfo } from "models";
import { Formik, Field } from "formik";
import config from "config";
import "./Location.css";
import { useNavigate } from "react-router-dom";

interface LocationProps {
  location: LocationInfo;
}

export const LocationAdmin = (props: LocationProps) => {
  const navigate = useNavigate()
  const { location } = props;
  const [error, setError] = useState("");
  const updateLocation = (updatedLocation: LocationInfo) => {
    let body;
    try {
      body = {
        visitorDashboard: JSON.parse(updatedLocation.guestDashboard),
        defaultDashboard: JSON.parse(updatedLocation.defaultDashboard),
      };
      fetch(
        `${config.SophieApiUrl}/api/locationInfo?locationName=${
          updatedLocation.locationId
        }&company=${updatedLocation.tenantId}&city=${
          updatedLocation.city
        }&countryId=${updatedLocation.countryId}&expiryTime=${
          updatedLocation.expiryDate ? updatedLocation.expiryDate : ""
        }&apiKey=${updatedLocation.apiKey}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
        .then((response) => (response.status === 200 ? response.json() : null))
        .then((result) => {
          if (result) {
            navigate('/');
          } else {
            setError("Error updating the location");
          }
        });
    } catch (error) {
      setError("Dashboard data is not valid");
    }
  };

  return (
    <>
      <Formik
        initialValues={{ ...location }}
        onSubmit={(values, { setSubmitting }) => {
          const updatedLocation = values;
          setSubmitting(false);
          updateLocation(updatedLocation);
        }}
        enableReinitialize
      >
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="text-black">
            <div className="flex py-2">
              <label className="w-2/12">Location Id</label>
              <Field type="text" name="locationId" placeholder="Location Id" />
            </div>
            <div className="flex py-2">
              <label className="w-2/12">Country Id</label>
              <Field type="text" name="countryId" placeholder="Country" />
            </div>
            <div className="flex py-2">
              <label className="w-2/12">City</label>
              <Field type="text" name="city" placeholder="City" />
            </div>
            <div className="flex py-2">
              <label className="w-2/12">Expiry Date</label>
              <Field type="text" name="expiryDate" placeholder="Expiry" />
            </div>
            <div className="flex py-2">
              <label className="w-2/12">API Key</label>
              <Field type="text" name="apiKey" placeholder="Api Key" />
            </div>
            <div className="flex py-2">
              <label className="w-2/12">Guest Dashboard</label>
              <Field name="guestDashboard">
                {({ field }) => (
                  <div className="w-5/12">
                    <textarea {...field} className="w-full h-64" />
                  </div>
                )}
              </Field>
            </div>
            <div className="flex py-2">
              <label className="w-2/12">Default Dashboard</label>
              <Field name="defaultDashboard">
                {({ field }) => (
                  <div className="w-5/12">
                    <textarea {...field} className="w-full h-64" />
                  </div>
                )}
              </Field>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="font-bold py-2 px-4 border rounded text-white0"
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
      {error && <div className="text-red1 font-bold">{error}</div>}
    </>
  );
};
