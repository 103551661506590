import React from "react";
import { Widget, TenantUser } from "models";
import { WidgetComponent } from "..";

interface WidgetListProps {
  widgets: Widget[];
  username: string;
  identity?: TenantUser;
}

export const WidgetList = (props: WidgetListProps) => {
  const { widgets, username, identity } = props;

  return (
    <>
      {widgets &&
        widgets.map(w => (
          <WidgetComponent
            key={w.name}
            name={username}
            widget={w}
            identity={identity}
            isPreview={false}
            help={{
              widgetName: w.settings.heading,
              description: w.help ? w.help.description : [],
              steps: w.help ? w.help.steps : [] 
            }}
          />
        ))}
    </>
  );
};
