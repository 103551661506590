import React, { useEffect, useState } from "react";
import NoContent from "../../NoContent/NoContent";

interface ImageProps {
  src: string;
}



export const ImageWidget = (
  props: ImageProps & React.HTMLAttributes<HTMLElement>
) => {
  const { src, ...rest } = props;
  const [valid, setValid] = useState<any>(false);

  useEffect(() => {
    const checkImage = async (url: string) => {
      const res = await fetch(url);
      const buff = await res.blob();

      setValid(buff.type.startsWith('image/'));
    }

    checkImage(src)
  }, [src]);

  return (
    <>
      {valid ?
        <img src={src} alt="User selected image" {...rest} />
        :
        <NoContent>
          <span>{ src ? "No image found" : "Image settings not configured. Navigate to Edit User | Screen layout & widgets | Image"}</span>
        </NoContent>
      }
    </>
  );
};
