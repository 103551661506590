import { useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import Modal from "components/Modal/Modal";
import { ReactComponent as CloseIcon } from "assets/images/circle-xmark.svg";
import FetchImage from "./FetchImage";
import UploadImage from "./UploadImage";
import { toast } from 'react-toastify';
import { toastError, toastSuccess } from "services/ToastService.service";

interface TakePhotoProps {
  disabled: boolean;
  upload: (base64: string) => Promise<boolean>;
  uploadFile: (file: File) => Promise<boolean>;
  uploadUrl: (url: string) => Promise<boolean>;
}

const TakePhoto = ({ disabled, upload, uploadFile, uploadUrl }: TakePhotoProps) => {
  const webcamRef = useRef<Webcam>();
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>();
  const [status, setStatus] = useState<'success' | 'failure' | null>();
  const [message, setMessage] = useState<string>();
  const [takePhoto, setTakePhoto] = useState(false);

  const handleOpen = () => {
    setStatus(null);
    setMessage(null);
    setImgSrc(null);
    setOpen(true);
  }

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setImgSrc(imageSrc);
    setMessage('Uploading...');

    upload(imageSrc).then(success => {
      if (success) {
        toastSuccess('Photo uploaded successfully', 'success-photo-upload');
        setStatus('success');
        setMessage('Success!');
      } else {
        toastError('Photo failed to upload', 'error-photo-upload');
        setStatus('failure');
        setMessage('Upload failed!');
      }
    })
  }

  const handleRecapture = () => {
    setStatus(null);
    setMessage(null);
    setImgSrc(null);
  }

  const messageClassName = useMemo(() => ['input-message'].concat(status ? [`input-message-${status}`] : []).join(' '), [status]);

  return (
    <>
      <button
        className="facial-recognition-button DarkBtn text-xl"
        type="button"
        disabled={disabled}
        onClick={handleOpen}
      >
        Add photo
      </button>
      {open && (
        <Modal >
          {takePhoto ? 
          <>
            <div className="flex flex-col p-4 bg-white1 rounded w-[60vw]">
              <div className="flex justify-between pb-4">
                <h1 className="text-2xl">Capture photo</h1>
                <button type="button" onClick={() => {
                  setOpen(false);
                  setTakePhoto(false);
                }}>
                  <div className="flex items-center ">
                    <CloseIcon className="mr-4 h-7" />
                  </div>
                </button>
              </div>
              <div className="flex flex-col">
                <div>
                  {imgSrc
                    ? <img src={imgSrc} />
                    : <Webcam
                      ref={webcamRef}
                      width={1280}
                      height={720}
                      mirrored={true}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        width: 1280,
                        height: 720,
                        facingMode: "user",
                      }}
                    />
                  }
                </div>
                <div className={`${messageClassName} text-base`}>{message}</div>
              </div>
              <div className="flex justify-center gap-x-8 mt-4">
                {imgSrc
                  ? <button type="button" className="facial-recognition-button DarkBtn text-xl" onClick={handleRecapture} disabled={!status}>Re-Capture</button>
                  : <button type="button" className="facial-recognition-button RedBtn text-xl" onClick={handleCapture}>Capture & Upload</button>
                }
                <button type="button" className="facial-recognition-button DarkBtn text-xl" onClick={() => setTakePhoto(false)}>Cancel</button>
              </div>
            </div>
          </> 
          :
          <>
            <div className="flex flex-col p-4 bg-white1 rounded-t w-[60vw]">              
              <div className="flex justify-between pb-4">           
                <h1 className="text-2xl">Add photo</h1>
                <button type="button" onClick={() => setOpen(false)}>
                  <div className="flex items-center ">
                    <CloseIcon className="mr-4 h-7" />
                  </div>
                </button>
              </div>
              <div className="flex justify-center flex-col gap-x-8 mt-4">
                <UploadImage disabled={disabled} upload={uploadFile} />
                <FetchImage disabled={disabled} upload={uploadUrl} />
                <div className="flex justify-start pt-4">
                  <button type="button" className="facial-recognition-button RedBtn w-1/3 text-xl" onClick={() => setTakePhoto(true)}>Capture photo</button>
                </div>
              </div>            
            </div>
            <div className="flex justify-between bg-grey1 rounded-b" style={{minHeight: '130px'}} />
          </>
        }
        </Modal>
      )}
    </>
  );
}

export default TakePhoto;
