import React, { useEffect, useState } from "react";

import config from "config";
import "./devopsbacklog.css";
import moment from "moment";
import { BacklogState, BacklogType } from "../../../models/enums";
import NoContent from "../../NoContent/NoContent.jsx"

// Test data
import { testSprintData } from "../../../testData/devopsBacklogTestData";

interface BacklogItem {
  id: string;
  title: string;
  state: string;
  effort?: string;
  assignedTo?: string;
  type: string;
  description: string;
  acceptanceCriteria: string;
  url: string;
}

interface DevopsWidgetProps {
  organisationId: string;
  projectName: string;
  projectId: string;
  teamId: string;
  connectionId: number;
  userProfileId: string;
  user: string;
  demo?: boolean;
}
interface Sprint {
  workItems: BacklogItem[];
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
}

interface BacklogItemProps {
  backlogItem: BacklogItem;
}

const BacklogItemRow = (props: BacklogItemProps) => {
  const { backlogItem } = props;

  return (
    <div className={`flex team-row text-left sm:block py-4 text-xl`}>
      <div className="w-7/12 ml-4 sm:w-full sm:font-bold pr-2">
        <a href={backlogItem.url} target="_blank" rel="noreferrer">
          {backlogItem.title}
        </a>
      </div>
      <div className="w-2/12 sm:w-full sm:pl-4">
        <span className="hidden sm:font-bold sm:inline sm:pr-2">State: </span>
        {backlogItem.state}
      </div>
      <div className="w-1/12 sm:w-full sm:pl-4">
        <span className="hidden sm:font-bold sm:inline sm:pr-2">Efforts:</span>
        {backlogItem.effort}
      </div>
      <div className="w-2/12 sm:w-full sm:pl-4">
        <span className="hidden sm:font-bold sm:inline sm:pr-2">Assigned:</span>
        {backlogItem.assignedTo == null ? "Unassigned" : backlogItem.assignedTo}
      </div>
    </div>
  );
};

// TODO: display backlog item details
// PBI - https://dev.azure.com/ssw/SSW.SophieHub/_workitems/edit/70064
// interface BacklogItemDetailsProps {
//   backlogItem: BacklogItem;
//   onClose: () => void;
// }

// const overlayStyle = {
//   backdropFilter: "blur(4px)",
// };

// const BacklogItemDetails = (props: BacklogItemDetailsProps) => {
//   const { onClose, backlogItem } = props;
//   const [stateClass, setStateClass] = useState("");

//   useEffect(() => {
//     switch (backlogItem.state.toLowerCase()) {
//       case "new":
//         setStateClass("bg-new");
//         break;
//       case "in progress":
//         setStateClass("bg-inprogress");
//         break;
//       case "committed":
//         setStateClass("bg-committed");
//         break;
//       case "done":
//         setStateClass("bg-done");
//         break;
//     }
//   }, [backlogItem.state, props.backlogItem.state]);

//   return (
//     <div className="h-full w-350px absolute right-0 shadow-1">
//       <div className="h-full w-full bg-white3 mt-3px" style={overlayStyle} />
//       <div className="text-black absolute top-0 w-full">
//         <div className="flex flex-row justify-between w-full bg-grey4 text-black2 h-20 p-4 mt-3px items-center shadow-0">
//           <h2 className="text-lg font-bold text-left">{backlogItem.title}</h2>
//           <button onClick={() => onClose()}>close</button>
//         </div>
//         <div className="flex flex-row p-4 mt-4">
//           <label className="font-bold">Effort: </label>
//           <p className="ml-4"> {backlogItem.effort}</p>
//         </div>
//         <div className="flex flex-row p-4">
//           <label className="font-bold">State: </label>
//           <div className={`ml-4 ${stateClass} rounded py-1 px-4 `}>
//             {backlogItem.state}
//           </div>
//         </div>
//         <div className="flex flex-col w-full p-4 items-start">
//           <label className="font-bold">Description:</label>
//           <div
//             className="text-left"
//             dangerouslySetInnerHTML={{ __html: backlogItem.description }}
//           />
//         </div>
//         <div className="flex flex-col w-full p-4 items-start">
//           <label className="font-bold">Acceptance Criteria:</label>
//           <div
//             className="text-left"
//             dangerouslySetInnerHTML={{ __html: backlogItem.acceptanceCriteria }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

export const DevOpsBacklogWidget = (props: DevopsWidgetProps) => {
  const [sprint, setSprint] = useState<Sprint>(null);

  const [daysRemaining, setDaysRemaining] = useState(null);
  const handleShowAll = (event) => {
    setShowAll(event.target.checked);
  };

  const [showAll, setShowAll] = useState(false);

  const {
    connectionId,
    organisationId,
    projectId,
    teamId,
    userProfileId,
    user,
  } = props;

  useEffect(() => {
    if (!sprint) {
      return;
    }
    const remaining = moment(sprint.endDate).diff(moment(), "days");
    setDaysRemaining(remaining);
  }, [sprint]);

  useEffect(() => {
    if (props.demo) {
      setSprint(testSprintData);
      return;
    }

    if (
      !connectionId ||
      !organisationId ||
      !projectId ||
      !teamId ||
      !userProfileId
    )
      return;

    fetch(
      `${config.SophieApiUrl}/api/devops/backlog?userProfileId=${userProfileId}&connectionId=${connectionId}&organisation=${organisationId}&projectId=${projectId}&teamId=${teamId}`
    )
      .then((response) => (response.json()))
      .then((data) => {
        if (data) {
          setSprint(data);
        }
      });
  }, [connectionId, organisationId, projectId, teamId, userProfileId, props.demo]);

  // TODO: Getting burndown chart
  // useEffect(() => {
  //   if (sprint == null) {
  //     setBurndown(null);
  //     return;
  //   }

  //   fetch(
  //     `${config.SophieApiUrl}/api/devops/burndown?userProfileId=${userProfileId}&connectionId=${connectionId}&organisation=${organisationId}&projectId=${projectId}&teamId=${teamId}&sprintId=${sprint.id}`
  //   )
  //     .then(response => response.blob())
  //     .then(data => {
  //       var img = URL.createObjectURL(data);
  //       setBurndown(img);
  //     });
  // }, [sprint, organisationId, projectId, teamId, connectionId, userProfileId]);
  
  return (
    <div className="flex flex-col items-start">
      {sprint && (
        <div className="flex team-header w-full py-4 justify-between sm:block text-xl">
          <div className="flex flex-wrap sm:block text-left">
            <div className="font-bold text-xl text-grey2 py-1 ml-2">
              {props.projectName} |
            </div>
            <div className="text-xl px-2 py-1">
              {sprint && sprint.name} (
              {daysRemaining < 0
                ? `${-daysRemaining} days ago`
                : `${daysRemaining} days remaining`}
              )
            </div>
          </div>
          {/* TODO: display burndown chart
          <img src={burndown} className="h-12" alt="sprint burndown" /> */}
          <div className="flex px-2 my-auto">
            <label className="switch">
              <input type="checkbox" name="showAll" onChange={handleShowAll} />
              <span className="slider round"></span>
            </label>
            <div className="my-auto mx-2">Show all</div>
          </div>
        </div>
      )}
      <div className="w-full text-xl">
        <div className="flex team-header w-full sm:hidden py-4">
          <div className="w-7/12 ml-4 text-left font-bold">Title*</div>
          <div className="w-2/12 text-left font-bold">State</div>
          <div className="w-1/12 text-left font-bold">Effort</div>
          <div className="w-2/12 text-left font-bold">Assigned</div>
        </div>
        <div>
          {sprint ?
            sprint.workItems
              .filter(
                (b) =>
                  (
                    // unfinished backlog or bug
                    ((b.type === BacklogType.backlogItem ||
                      b.type === BacklogType.bug) &&
                      b.state !== BacklogState.done) ||
                    // or open userstory
                    (b.type === BacklogType.userStory &&
                      b.state !== BacklogState.closed)
                  ) &&
                  // show all or show assigned
                  (showAll || (b.assignedTo?.toLowerCase().includes(user.toLowerCase())))
              )
              .map((b) => <BacklogItemRow backlogItem={b} key={b.id} />)
            :
            <NoContent>
              <span>No backlog items assigned to you.</span>
            </NoContent>
          }
        </div>
        <div className="w-full text-right px-4 py-4 text-base">
          * Click on the PBI's title to open the PBI's page
        </div>
      </div>
    </div>
  );
};
