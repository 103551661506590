import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { TenantUser } from "../../models";
import SwitchUserIcon from "assets/images/switch-user@3x.png";
import EditUserIcon from "assets/images/fa-solid-user-edit.svg";
import { ReactComponent as LogoutIcon } from "assets/images/arrow-right-from-bracket.svg";
import { ReactComponent as ShareIcon } from "assets/images/share-from-square.svg";
import { ReactComponent as RobotIcon } from "assets/images/robot-regular.svg";
import EmailIcon from "assets/images/bx-bx-mail-send.svg";
import "./Login.css";

// eslint-disable-next-line
const Typeahead = require("react-typeahead").Typeahead;
interface Props {
  onLogin: (email: string) => void;
  onLogout: () => void;
  identity?: TenantUser;
  users?: TenantUser[];
  qrCode: any;
}
const Login = (props: Props) => {
  const loginField = useRef(null);
  const { identity, users } = props;
  const [usernameList, setUsernameList] = useState(null);
  const [switchUser, setSwitchUser] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [loginPressed, setLoginPressed] = useState(false);

  const onSelect = (selected: any) => {
    if (selected) {
      props.onLogin(selected.value);
      setLoginPressed(false);
    }
  };

  const pressDemo = () => {
    props.onLogin('demo');
  }

  const pressLogin = () => {
    localStorage.setItem("collapsed", JSON.stringify([]));
    setLoginPressed(true);
    setShowShare(false);
  };

  const pressLogout = () => {
    localStorage.setItem("collapsed", JSON.stringify([]));
    props.onLogout();
  };

  const pressSwitchUser = () => {
    setSwitchUser(true);
    setShowShare(false);
  };

  const pressShare = () => {
    setShowShare(!showShare);
    setSwitchUser(false);
    setLoginPressed(false);
  };

  useEffect(() => {
    if (loginField.current) {
      loginField.current.focus();
    }
  });

  useEffect(() => {
    if (users) {
      const aList = users.map((x) => {
        return {
          label: `${x.name} | ${x.userName}`,
          searchValue: `${x.userName} ${x.name}`,
          value: x.userName,
        };
      });
      setUsernameList(aList);
    }
  }, [users]);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      pressShare();
    }
  }, []);

  if (identity && users) {
    return (
      <div className="menu-container">
        <div className="menu-main-container text-lg">
          <button title="Edit User">
            <Link to={`/${identity.tenantId}/profile/${identity.id}`}>
              <img src={EditUserIcon} alt="editUser" className="button-icon" />
              <span className="whitespace-nowrap">Edit User</span>
            </Link>
          </button>
          { identity["username"] != "demo" && 
            <>
              <span className="line"></span>
              <button
                onClick={pressSwitchUser}
                title="Switch User"
              >
                <img src={SwitchUserIcon} alt="switchUser" className="button-icon" />
                <span className="whitespace-nowrap">Switch User</span>
              </button>
              </>
        }           
          <span className="line"></span>
          <button onClick={pressShare} title="Share">
            <ShareIcon className="button-icon" />
            <span>Share</span>
          </button>
          { identity["username"] != "demo" && 
            <>
              <span className="line"></span>
              <button onClick={pressDemo} title="Demo">
                <RobotIcon className="button-icon" />
                <span>Demo</span>
              </button>
              </>
        }
          <span className="line"></span>
          <button onClick={pressLogout} title="Logout">
            <LogoutIcon className="button-icon" />
            <span>Logout</span>
          </button>
        </div>
        {sharePanel(showShare, props.qrCode)}
        {switchUser && (
          <Typeahead
            displayOption="label"
            filterOption="searchValue"
            options={usernameList}
            maxVisible={3}
            placeholder="Please enter your name/username"
            className="menu-sub-container"
            onOptionSelected={onSelect}
            customClasses={{
              input: "typeahead-user-text-input",
              results: "typeahead-user-list",
              listItem: "typeahead-user-list-item",
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className="menu-container">
      <div className="menu-main-container gap-x-2 sm:gap-x-2 text-lg">
        <button onClick={pressShare} title="Share">
          <ShareIcon className="button-icon" />
          <span>Share</span>
        </button>
        <span className="line"></span>
        <div className="flex items-center gap-x-2 sm:gap-x-2">
          <button onClick={pressLogin} title="Select User">
            <img src={SwitchUserIcon} alt="selectUser" className="button-icon" />
            <span className="whitespace-nowrap">Select User</span>
          </button>
          <span className="line"></span>
          <button onClick={pressDemo} title="Demo">
            <RobotIcon className="button-icon" />
            <span className="whitespace-nowrap">Demo</span>
          </button>
        </div>
      </div>
      {sharePanel(showShare, props.qrCode)}
      {loginPressed && (
        <Typeahead
          displayOption="label"
          filterOption="searchValue"
          options={usernameList}
          maxVisible={3}
          placeholder="Please enter your name/username"
          className="menu-sub-container"
          onOptionSelected={onSelect}
          customClasses={{
            input: "typeahead-user-text-input",
            results: "typeahead-user-list",
            listItem: "typeahead-user-list-item-login",
          }}
        />
      )}
    </div>
  );
};

const sharePanel = (showShare, qrCode) => {
  if (showShare) {
    return (
      <div className="menu-sub-container flex text-xl">
        <div className="w-1/2">
          <img
            className="mx-auto mt-8 mt-3 w-32"
            src={`data:image/png;base64,${qrCode}`}
            alt="qrCode"
          />
          <div className="mx-auto text-white2 text-center">Open on mobile</div>
        </div>
        <div className="w-1/2">
          <a
            href={`mailto:?subject=SophieHub%20Url&body=${window.location.href}`}
          >
            <img src={EmailIcon} alt="email" className="h-8 mx-auto mt-20" />
          </a>
          <div className="mx-auto text-white2 text-center">Share via Email</div>
        </div>
      </div>
    );
  }
};

export default Login;
