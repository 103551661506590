import { useDrag } from 'react-dnd'
import { WidgetSettingsInfo } from 'models';
import { WidgetCard } from '../WidgetCard/WidgetCard';

interface DraggableCardProps {
  widgetSettingInfo: WidgetSettingsInfo,
  addWidget: (widgetSettingInfo: WidgetSettingsInfo, hoverIndex?: number) => void,
}

const DraggableCard = ({ widgetSettingInfo, addWidget }: DraggableCardProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "WIDGET_CARD",
    item: widgetSettingInfo,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (widgetSettingInfo, monitor) => {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        const { type, hoverIndex } = monitor.getDropResult<{type: string; hoverIndex: number}>();

        switch (type) {
          case 'INSERT':
            addWidget(widgetSettingInfo, hoverIndex);
            break;
          default:
        }
      }
    }
  }), [addWidget]);

  return (
    <div ref={drag} style={{ transform: 'translate(0,0)', opacity: isDragging ? 0 : 1 }}>
      <WidgetCard
        className="m-2"
        header={widgetSettingInfo.displayName}
        icon={widgetSettingInfo.icon}
        description={widgetSettingInfo.description}
      />
    </div>
  )
}

export default DraggableCard;