import React from "react";
import { Avatar } from "components";
import { Field } from "formik";
import { LocationInfo } from "models";

interface UserDetailsProps {
  values?: any;
  className?: string;
  locations: LocationInfo[];
}

export const UserDetails = (props: UserDetailsProps) => {
  const {
    values: { name, avatarUrl },
  } = props;

  // Proper-case string
  const properCaseWord = (word) => {
    return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
  }

  // Filter unique cities
  const cities = [...new Set(props.locations.map(location => location.city.toLowerCase()))];

  return (
    <div className={`${props.className}`}>
      {/* <input type="hidden" value={tenantId} /> */}
      <div className="flex flex-row w-full text-xl">
        <Avatar url={avatarUrl} name={name} className="w-24 h-24 mr-8" />
        <div className="flex flex-col items-start w-full mr-4 text-18">
          <label>Avatar</label>
          <div className="flex flex-row h-12 w-full mt-2">
            <Field
              type="text"
              name="avatarUrl"
              className="flex-grow h-full rounded-l rounded-r-none px-4 bg-white1 focus:bg-white2"
              placeholder="avatar URL"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row my-10">
        <div className="flex flex-col mr-4 w-1/2 items-start">
          <label>Name</label>
          <Field
            type="text"
            name="name"
            className="w-full text-xl rounded h-12 px-4 my-2 bg-white1  focus:bg-white2"
          />
        </div>
        <div className="flex flex-col text-18 mr-4 w-1/2 items-start">
          <label>Username</label>
          <Field
            type="text"
            name="username"
            className="w-full text-xl rounded h-12 px-4 my-2 bg-white1 focus:bg-white2"
          />
        </div>
      </div>
      <div className="flex flex-row mb-10">
        <div className="flex flex-col text-18 mr-4 w-1/2 items-start">
          <label>Email</label>
          <Field
            type="text"
            name="email"
            className="w-full text-xl rounded h-12 px-4 my-2 bg-white1 focus:bg-white2"
          />
        </div>
        <div className="flex flex-col text-18 mr-4 w-1/2 items-start">
          <label>Location</label>
          <Field
            as="select"
            name="defaultLocation"
            className="w-full rounded text-xl h-12 px-4 my-2 bg-white1 focus:bg-white2"
          >
            <option value={null}>
              Select location...
            </option>
            {cities.map((location, index) => {
              return (
                <option value={location} key={index}>
                  {properCaseWord(location)}
                </option>
              );
            })}
          </Field>
        </div>
      </div>
    </div>
  );
};
