import React from "react";

const NoContent = (props) => {
  return (
    <div className="flex justify-center items-center w-full h-[10rem] p-7">
      <div className={`flex justify-center items-center w-full h-full bg-white1 font-bold ${props.className || ""}`} style={{fontSize: "1.125rem", color: "#797979", ...props.style}}>
        {props.children}
      </div>
    </div>
  )
}

export default NoContent;
