import React from "react";
import { useField } from "formik";

interface WidgetSettingsFieldProps {
  label: string;
  widgetIndex: number;
  defaultValue: string;
}
const SettingsField = (props: WidgetSettingsFieldProps & any) => {
  const { label, widgetIndex, defaultValue, name, disabled, ...other } = props;
  const [field] = useField(props);
  
  return (
    <div className="flex flex-row my-6 items-center">
      <label className="text-black text-sm w-1/3">{label}</label>
      <div>
        <input
          disabled={disabled}
          className={`${disabled ? "opacity-50" : "opacity-100"} appearance-none border border-grey3 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          {...field}
          {...other}
        />
      </div>
    </div>
  );
};

export default SettingsField;
