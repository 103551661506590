import React from "react";
import AzureDevopsWidgetSettings from "./AzureDevOps/AzureDevOps";
import { WidgetSettingsInfo, UserProfile, TenantUser } from "models";
import SettingsField from "./SettingsField/SettingsField";
import MyTeamSettings from "./MyTeam/MyTeamSettings";
import { Formik, useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { 
  toastSuccess, 
  toastError 
} from "services/ToastService.service";

export interface WidgetSettingsProps {
  settingsInfo: WidgetSettingsInfo;
  widgetIndex: number;
  user: UserProfile;
  users: TenantUser[];

  onClose: () => void;
  onApply: () => void;
}

const WidgetSettings = (props: WidgetSettingsProps) => {
  const {
    settingsInfo: { name, icon, settings, displayName },
    onClose,
    user,
    widgetIndex,
    users
  } = props;

  const {
    setFieldValue,
  } = useFormikContext();

  const updateTwitterWidgetSettings = (updatedUser: UserProfile) => {
    const twitterHandle = updatedUser.dashboard.widgets.find(w => w.name === "twitter").settings.userHandle;
    
    if (twitterHandle !== user.dashboard.widgets.find(w => w.name === "twitter").settings.userHandle) {
      updatedUser.dashboard.widgets.find(w => w.name === "twitter").settings.heading = twitterHandle ? `Twitter - @${twitterHandle}` : `Twitter - @SSW_tv`;
    }
  }

  const onFormSubmit = (values, { setSubmitting }) => {
    const updatedUser = { ...values };

    try {
      if (name === "Twitter") {
        updateTwitterWidgetSettings(updatedUser);
      } 

      // Update the unsaved form with the updated details
      user.dashboard.widgets = updatedUser.dashboard.widgets;

      // Update the formik state with the updated user
      setFieldValue('dashboard.widgets', updatedUser.dashboard.widgets);
      
      toastSuccess('Widget settings applied successfully', `save-widget`);
      onClose();
    } catch (error) {
      toastError('Widget settings failed to apply', `error-widget`);
      console.log(error);
    }
    
    setSubmitting(false);
  }

  let fields = null;
  switch (name) {
    case "DevopsBacklog":
      fields = (
        <AzureDevopsWidgetSettings
          userId={user.id}
          userConnections={user.connections}
          settings={settings}
          widgetIndex={widgetIndex}
        />
      );
      break;
    case "MyTeam":
      fields = (
        <MyTeamSettings
          widgetIndex={widgetIndex}
          users={users}
          settings={settings}
        />
      );
      break;
    default:
      fields = settings.map((s) => {
        if (name !== "Twitter" || s.name !== 'heading') {
          return (
            <SettingsField
              key={s.name}
              label={s.displayName}
              type="text"
              widgetIndex={widgetIndex}
              defaultValue={s.name}
              disabled={false}
              name={`dashboard.widgets[${widgetIndex}].settings.${s.name}`}
            />
          );
        }});
  }
  
  return (
    <div className="flex flex-col bg-white2 sm:w-full sm:mx-0">
      <div className="flex flex-row h-20 bg-grey4 shadow-0 z-10 items-center">
        <img
          src={`/images/${icon}`}
          className="w-8 mx-8"
          alt={`${displayName} icon`}
        />
        <h2 className="text-lg font-bold">{displayName}</h2>
      </div>
     <Formik
        initialValues={{
          ...user,
        }}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex-col px-8 py-4">{fields}</div>
            <div className="flex flex-row my-5 mx-4 justify-end">
              <button className="TextBtn LightBtn" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </button>
              <button className="TextBtn DarkBtn mx-4" type="submit" disabled={isSubmitting}>
                Apply
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default WidgetSettings;
