import Footer from "components/Footer/Footer";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
  return (
    <div className="add-layout admin-layout">
      <Outlet />
      <Footer />
    </div>
  )
}

export default AdminLayout;
