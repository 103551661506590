import { useMemo, useState } from "react";

interface FetchImageProps {
  disabled: boolean;
  upload: (url: string) => Promise<boolean>;
}

const FetchImage = ({ disabled, upload }: FetchImageProps) => {
  const [url, setUrl] = useState<string>("");
  const [status, setStatus] = useState<'success' | 'failure' | null>();
  const [message, setMessage] = useState<string>();

  const handleUrlChange = (event) => {
    setStatus(null);
    setMessage(null);
    setUrl(event.target.value);
  }

  const handleFetch = () => {
    setStatus(null);
    setMessage('Uploading...');

    upload(url).then(success => {
      if (success) {
        setStatus('success');
        setMessage('Success!');
        setUrl("");
      } else {
        setStatus('failure');
        setMessage('Upload failed!');
      }
    })
  }

  const containerClassName = useMemo(() => ['input-field-container'].concat(url ? ['text-black'] : ['text-grey1']).join(' '), [url]);
  const messageClassName = useMemo(() => ['input-message'].concat(status ? [`input-message-${status}`] : []).join(' '), [status]);

  return (
    <div className="flex flex-col w-1/3 max-w-md">
      <div className="flex">
        <div className={containerClassName}>
          <input
            className="input-field"
            placeholder="Fetch from URL"
            value={url}
            disabled={disabled}
            onChange={handleUrlChange}
          />
        </div>
        <button className="input-button DarkBtn" type="button" disabled={!url} onClick={handleFetch}>Fetch*</button>
      </div>
      <div className={messageClassName}>{message}</div>
    </div>
  );
}

export default FetchImage;