import React from "react";
import { WeatherInfo } from "../../../models";

interface WeatherForecastProps {
  days: WeatherInfo[];
}

interface ForecastDayProps {
  date: string;
  minTemp: number;
  maxTemp: number;
  weatherDescription: string;
  icon: string;
}

const ForecastDay = (props: ForecastDayProps): JSX.Element => {
  const { date, minTemp, maxTemp, weatherDescription, icon } = props;

  const d = new Date(date);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const dayOfWeek = d !== null ? days[d.getDay()] : "";

  return (
    <div className="forecast-day w-1/3 py-2 text-xl">
      <div className="font-bold">{dayOfWeek}</div>
      <img
        alt={weatherDescription}
        className="weather-icon"
        src={`http://openweathermap.org/img/wn/${icon}@2x.png`}
      />
      <p>{weatherDescription}</p>
      <p>min: {minTemp.toFixed(0)}°</p>
      <p>max: {maxTemp.toFixed(0)}°</p>
    </div>
  );
};

const WeatherForecast = (props: WeatherForecastProps): JSX.Element => {
  const { days } = props;

  return (
    <div>
      <div className="text-2xl font-bold">Forecast</div>
      <div>
        <div className="flex flex-wrap">
          {days.map(w => (
            <ForecastDay
              key={w.date}
              date={w.date}
              minTemp={w.minTemp}
              maxTemp={w.maxTemp}
              weatherDescription={w.weatherDescription}
              icon={w.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeatherForecast;
