import React, { useState } from "react";
import { Visitor, Widget, TenantUser } from "models";
import {
  ImageWidget,
  FrameWidget,
  AppointmentsWidget,
  Greeting,
  BotWidget,
  VisitorGreeting,
  WeatherWidget,
  HolidaysWidget,
  TwitterWidget,
  DevOpsActiveProjectsWidget,
  DevOpsBacklogWidget,
  DevOpsAppInsights,
  MyTeamWidget,
  News,
} from "components/Widgets";
import {
  WidgetHelpPopUp,
  WidgetHelpPopUpProps
} from "components/WidgetHelpPopUp/WidgetHelpPopUp"
import {
  toastSuccess,
  toastError,
} from "services/ToastService.service";
import { MdClose, MdInfoOutline } from "react-icons/md";
import { toast } from 'react-toastify';

interface Props {
  widget: Widget;
  name: string;
  identity: TenantUser;
  visitor?: Visitor;
  isPreview: boolean;
  help: WidgetHelpPopUpProps;
}

const getWidget = (name: string, widget: Widget, identity: TenantUser) => {
  let widgetName = widget.name;

  if (widgetName) {
    widgetName = widgetName.toLowerCase();
  }

  switch (widgetName) {
    case "bot":
      return <BotWidget name={name} />;
    case "image":
      return <ImageWidget src={widget.settings.image} />;
    case "visitorgreeting":
      return <VisitorGreeting />;
    case "greeting":
      return (
        <Greeting name={name} customGreeting={widget.settings.displayText} />
      );
    case "devopsactiveprojects":
      return <DevOpsActiveProjectsWidget projects={[]} />;
    case "devopsbacklog":
      return (
        <DevOpsBacklogWidget
          organisationId={widget.settings.organisationId}
          projectId={widget.settings.projectId}
          projectName={widget.settings.projectName}
          teamId={widget.settings.teamId}
          connectionId={widget.settings.connectionId}
          userProfileId={identity.id}
          user={identity.name}
          demo={widget.settings.demo}
        />
      );
    case "devopsappinsights":
      return (
        <DevOpsAppInsights
          appId={widget.settings.appId}
          apiKey={widget.settings.apiKey}
          demo={widget.settings.demo}
        />
      );
    case "iframe":
      return (
        <FrameWidget
          width={widget.settings.width}
          height={widget.settings.height}
          src={widget.settings.source}
        />
      );
    case "appointments":
      if (identity) {
        return <AppointmentsWidget email={identity.email!} demo={widget.settings.demo} />;
      }
      return <></>;
    case "weather":
      return (
        <WeatherWidget
          city={widget.settings.city}
          country={widget.settings.country}
        />
      );
    case "holidays":
      return <HolidaysWidget />;
    case "twitter":
      return (
        <TwitterWidget
          userHandle={
            widget.settings.userHandle ? widget.settings.userHandle : "SSW_tv"
          }
          count={widget.settings.count ? widget.settings.count : 3}
        />
      );
    case "myteam":
      return <MyTeamWidget emailString={widget.settings.teamMates} demo={widget.settings.demo} />;
    case "news":
      return <News />;
    default:
      return;
  }
};

export const WidgetComponent = (props: Props) => {
  const { widget, name, identity, isPreview, help } = props;
  const [isClosed, setIsClosed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const closeClick = () => {
    setIsClosed(true);

    // Update localStorage array with new closed widget
    const local = [];
    local.push(...JSON.parse(localStorage.getItem("collapsed")));
    local.push(widget);
    localStorage.setItem("collapsed", JSON.stringify(local));

    // Display temporary close notification
    toastError("This widget is now temporarily closed. Refresh the page to bring it back. For permanent removal, go to Edit User | Screen layout & widgets.", "temporary-close");
  };

  return (
    <div
      key={widget.name}
      className={`w-full bg-transparent border-b-4 rounded-b-sm border-white0 ${
        isClosed ? "hidden" : ""
      }`}
    >
      <div className="w-full flex items-end bg-red1 border-b-2 border-red2 rounded-t-sml h-20">
        <div className="widget-icon-background rounded-b-full bg-red1 border-red2 border-b-2 relative w-32 h-24 ml-3 flex items-center justify-center z-10">
          {widget.settings.icon && (
            <img src={widget.settings.icon} alt="Widget header icon" />
          )}
        </div>
        <div className="text-2xl flex items-center w-full text-left text-white2 my-auto ml-4">
          {widget.settings.heading}
          {!isPreview && (
            <div className="ml-4 flex justify-center items-center">
              <button onClick={() => setModalOpen(true)}><MdInfoOutline color="white" size={26} /></button>
              { modalOpen && <WidgetHelpPopUp widgetName={help.widgetName} description={help.description} steps={help.steps} closeModal={setModalOpen}  />}
            </div>
          )}
        </div>
        {!isPreview && (
          <div className="pr-4 my-auto">
            <button onClick={closeClick}>
              <MdClose color="white" size={32} />
            </button>
          </div>
        )}
      </div>
      <div className="bg-white2 pb-2">
        {getWidget(name, widget, identity)}
      </div>
    </div>
  );
};
