import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import config from "../../config";
import { useParams } from "react-router-dom";
import "./visitor.css";

interface AdminParams {
  tenantId: string;
  locationId: string;
}

const Visitor = () => {
  const params = useParams<keyof AdminParams>();
  const [visitor, setVisitor] = useState("");
  const [contact, setContact] = useState("");

  const submitNewVisitor = (name: string, contactName: string) => {
    const { tenantId, locationId } = params;

    const payload = { name, contactName };
    fetch(`${config.SophieApiUrl}/api/ExpectedVisitor/${tenantId}/${locationId}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    }).then(result => {
      setVisitor(name);
      setContact(contactName);
    });
  };

  useEffect(() => {
    const { tenantId, locationId } = params;

    fetch(`${config.SophieApiUrl}/api/ExpectedVisitor/${tenantId}/${locationId}`)
      .then(response => (response.status === 200 ? response.json() : null))
      .then(result => {
        if (result) {
          setVisitor(result.name);
          setContact(result.contactName);
        } else {
          setVisitor("");
        }
      });
  }, [params]);

  return (
    <div className="admin">
      <h1>Guests Registration</h1>
      <p>
        <strong>Current registered Visitor:</strong> {visitor}
        <br />
        <strong>Here to see:</strong> {contact}
      </p>

      <h4>Registration</h4>
      <Formik
        initialValues={{ name: "", contact: "" }}
        onSubmit={(values, { setSubmitting }) => {
          const { name, contact } = values;
          submitNewVisitor(name, contact);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field type="string" name="name" placeholder="Visitor Name" />
            <Field type="string" name="contact" placeholder="Contact Name" />
            <br />
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Visitor;
