import { useMemo, useRef, useState } from "react";

interface UploadImageProps {
  disabled: boolean;
  upload: (file: File) => Promise<boolean>;
}

const UploadImage = ({ disabled, upload }: UploadImageProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [file, setFile] = useState<File>();
  const [status, setStatus] = useState<'success' | 'failure' | null>();
  const [message, setMessage] = useState<string>();

  const handleFileChange = (event) => {
    const file = (event.target as HTMLInputElement).files[0];
    const extension = file.name.split('.').pop();
    const isValidType = ['jpg', 'jpeg', 'png'].includes(extension.toLowerCase());

    if (isValidType) {
      setFile(file);
      setStatus(null);
      setMessage(null);
    } else {
      setFile(null);
      setStatus('failure');
      setMessage('Invalid format. Accept: .jpg, .jpeg, .png');
    }

    // reset
    if (inputRef) {
      inputRef.current.value = null;
    }
  };

  const handleUpload = () => {
    setStatus(null);
    setMessage('Uploading...');

    upload(file).then(success => {
      if (success) {
        setStatus('success');
        setMessage('Success!');
        setFile(null);
      } else {
        setStatus('failure');
        setMessage('Upload failed!');
      }
    });
  };

  const containerClassName = useMemo(() => ['input-field-container'].concat(file?.name ? ['text-black'] : ['text-grey1']).join(' '), [file]);
  const labelClassName = useMemo(() => ['input-field'].concat(disabled ? [] : ['cursor-pointer']).join(' '), [disabled]);
  const messageClassName = useMemo(() => ['input-message'].concat(status ? [`input-message-${status}`] : []).join(' '), [status]);

  return (
    <div className="flex flex-col w-1/3 max-w-xs">
      <div className="flex">
        <div className={containerClassName}>
          <label htmlFor="file-upload-field" className={labelClassName}>{file ? file.name : 'Choose file...'}</label>
          <input
            ref={inputRef}
            name="file-upload-field"
            id="file-upload-field"
            type="file"
            accept="image/*"
            className="hidden"
            disabled={disabled}
            onChange={handleFileChange}
          />
        </div>
        <button className="input-button DarkBtn" type="button" disabled={!file?.name} onClick={handleUpload}>Upload*</button>
      </div>
      <div className={messageClassName}>{message}</div>
    </div>
  );
}

export default UploadImage;