import React from "react";
import { Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";
import { MdMic } from "react-icons/md";
import './Voice.css';

class VoiceRecognitionWidgetState {
  isListening: boolean;
}

class VoiceRecognitionWidgetProps {
  onMessageEnd: (message: string) => void;
  onIntermediateMessage: (message: string) => void;
  children?: React.ReactNode;
}

export class VoiceRecognitionWidget extends React.Component<
  VoiceRecognitionWidgetProps,
  VoiceRecognitionWidgetState
> {
  defaultLottieOptions = {
    autoplay: false,
    loop: true,
    isClickToPauseDisabled: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  // eslint-disable-next-line
  // @ts-ignore - Cannot find name 'webkitSpeechRecognition'. [2304]
  recognition: webkitSpeechRecognition;

  //Keeping reference to an element at bottom of messages, so we can scroll to bottom
  finishedRecognition = new Subject<string>();
  widgetListening = new Subject();

  dispose = new Subject();

  constructor(props: VoiceRecognitionWidgetProps) {
    super(props);
    this.state = {
      isListening: false
    };

    this.toggleListening = this.toggleListening.bind(this);
    this.initRecognition();
    this.finishedRecognition
      .pipe(
        filter(
          transcript =>
          transcript?.length > 0
        ),
        takeUntil(this.dispose)
      )
      .subscribe(transcript => {
        this.props.onMessageEnd(transcript);
        console.log("end");
      });

    this.widgetListening
      .pipe(debounceTime(10000), takeUntil(this.dispose))
      .subscribe(value => {
        this.recognition.stop();
        console.log(`Lets tell the world I'm ${value ? "awake" : "asleep"}`);
        this.setState({ ...this.state, isListening: false });
      });
  }

  initRecognition() {
    try {
      // eslint-disable-next-line
      // @ts-ignore - Cannot find name 'webkitSpeechRecognition'. [2304]
      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = "en-US";

      this.recognition.onresult = event => {
        console.log(`Results at index: ${event.resultIndex}`);
        console.log(`Results count: ${event.results.length}`);

        const results = [...event.results];
        results.splice(0, event.resultIndex);

        const transcript = results
          .map(r => r[0].transcript)
          .reduce((a, b) => `${a} ${b}`);

        if (!this.state.isListening) {
          console.log(`Ignoring: ${transcript}`);
          return;
        }

        console.log(`Transcript is ${transcript}`);

        this.props.onIntermediateMessage(transcript);
        this.widgetListening.next(null);

        if (results[results.length - 1].isFinal) {
          this.finishedRecognition.next(transcript);
        }
      };
    } catch (e) {
      console.error(e, "Unable to start voice recognition.");
    }
  }

  toggleListening() {
    console.log(`Turning on sophie`);
    if (this.state.isListening) {
      this.recognition.stop();
    } else {
      this.widgetListening.next(null);
      this.recognition.start();
    }

    this.setState({ ...this.state, isListening: !this.state.isListening });
  }

  componentWillUnmount() {
    this.dispose.next(null);
  }

  render() {
    if (this.recognition) {
      return (
        <div className="listening-container">{this.props.children ?? (
          <>
            <button id="speech" className="btn" onClick={this.toggleListening}>
              <MdMic aria-hidden="true" />
            </button>
            <div className={`${this.state.isListening ? 'pulse-ring' : ''} static-ring`}></div>
          </>
        )}</div>
      );
    } else {
      return "";
    }
  }
}
