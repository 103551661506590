import React from "react";
import { ReactComponent as ThumbGrip } from "assets/images/thumb-grip.svg";

interface WidgetCardProps {
  icon: string;
  header: string;
  description: string;
  className?: string;
}

export const WidgetCard = (props: WidgetCardProps) => {
  const { icon, header, description, className } = props;

  return (
    <div
      className={`${className} justify-between items-center flex shadow-0 bg-grey4 rounded-lg flex flex-row h-32 cursor-move`}
    >
      <img
        src={`/images/${icon}`}
        className="w-12 m-8"
        alt={`${header} icon`}
      />
      <div className="flex flex-col items-start w-56">
        <p className="font-bold text-lg">{header}</p>
        <p className="text-grey2 text-lg text-left mr-2">{description}</p>
      </div>
      <ThumbGrip className="mr-4" />
    </div>
  );
};
