import Footer from "components/Footer/Footer";
import { Outlet } from "react-router-dom";
import "./layout.css";

const MainLayout = () => {
  return (
    <div className="app-layout main-layout flex flex-col justify-between">
      <Outlet />
      <Footer />
    </div>
  )
}

export default MainLayout;
