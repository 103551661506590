import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { timer } from "rxjs";
import Home from "./containers/Home";
import Admin from "./Features/Admin";
import Visitor from "./containers/Visitor";
import Landing from "./containers/Landing";
import UserProfileAdmin from "./Features/UserProfile";
import TenantUsers from "./Features/TenantUsers";
import "./App.css";
import { ToastContainer, Slide } from 'react-toastify';
import MainLayout from "layout/MainLayout";
import AdminLayout from "layout/AdminLayout";

function App() {
  // First tick will happen one hour after page is loaded, then once every hour
  // To make sure after refresh, it won't refresh again on the be
  const anHour: number = 1000 * 60 * 60;
  timer(anHour, anHour).subscribe(() => {
    if (new Date().getHours() === 0) {
      document.location.reload();
    }
  });

  return (
    <div className="App text-center flex flex-col h-full w-full">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        transition={Slide}
        theme="colored"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="" element={<Landing />} />
          </Route>
          <Route
            path="/sophie/:tenantId"
            element={<MainLayout />}
          >
            <Route path="" element={<Home />} />
            <Route path=":locationId" element={<Home />} />
          </Route>
          <Route
            path="/:tenantId/profile/:userId"
            element={<AdminLayout />}
          >
            <Route path="" element={<UserProfileAdmin />}
            />
          </Route>
          <Route path="/:tenantId/profiles"
            element={<AdminLayout />}
          >
            <Route path="" element={<TenantUsers />} />
          </Route>
          <Route
            path="/admin/:tenantId/:locationId"
            element={<MainLayout />}
          >
            <Route path="" element={<Visitor />}
            />
          </Route>
          <Route path="/TEMPadmin/:tenantId/" element={<MainLayout />}
          >
            <Route path="" element={<Admin />} />
          </Route>
          <Route element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
