import React, { useState, useRef, ReactNode, useEffect, useCallback } from "react";
import update from 'immutability-helper'
import {
  WidgetSettingsInfo,
  DashboardWidget,
  Dashboard,
  Widget,
  UserProfile,
  TenantUser,
} from "models";
import Background from "assets/images/background.png";
import { FieldArray, FieldArrayRenderProps } from "formik";
import Modal from "components/Modal/Modal";
import WidgetSettings from "../WidgetSettings";
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import WidgetPreview from "./WidgetPreview";
import DraggableCard from "./DraggableCard";

interface DroppableProps {
  children?: ReactNode,
  className?: string
}

interface DashboardConfigProps {
  className?: string;
  widgets: WidgetSettingsInfo[];
  dashboard: Dashboard;
  currentUser: UserProfile;
  users: TenantUser[];
}

const Droppable = ({ children, className }: DroppableProps) => {
  const [_, drop] = useDrop(() => ({
    accept: ['WIDGET_CARD', 'WIDGET_PREVIEW'],
    drop: (_, monitor) => {
      if (monitor.isOver({shallow: true})) {
        return { type: 'INSERT', hoverIndex: 0 }
      }
    },
  }));

  return (
    <div ref={drop} className={className}>{children}</div>
  )
}

export const DashboardConfig = ({ users, widgets, className, dashboard, currentUser }: DashboardConfigProps) => {
  const [widgetList, setWidgetList] = useState<DashboardWidget[]>([]);
  const [currentEditUserWidget, setCurrentEditUserWidget] = useState<{
    userWidget: Widget;
    index: number;
    widgetInfo: WidgetSettingsInfo;
  } | null>(null);

  const arrayHelpersRef = useRef<FieldArrayRenderProps>();

  const updateUser = () => {
    currentUser = {
      ...currentUser,
      dashboard: {
        widgets: widgetList,
      }
    }
  }

  useEffect(() => {
    updateUser();
  }, [widgetList] )

  // initialize a temp widget list for dnd
  useEffect(() => {
    dashboard?.widgets && setWidgetList(dashboard.widgets);
  }, [dashboard?.widgets]);

  const addWidget = useCallback((widgetSettingInfo, insertIndex?) => {
    // TODO: add identifiers for duplicates
    const name = widgetSettingInfo.name.toLowerCase();

    if (dashboard?.widgets.find((w) => w.name === name)) {
      return;
    }
    
    const newWidget = {
      name,
      help: {},
      settings: {}
    } as DashboardWidget;

    widgetSettingInfo.settings.forEach((setting) => {
      newWidget.settings[setting.name] = setting.defaultValue;
    });

    newWidget["help"] = widgetSettingInfo.help;

    insertIndex !== null && insertIndex !== undefined
      ? arrayHelpersRef.current.insert(insertIndex, newWidget)
      : arrayHelpersRef.current.push(newWidget);
  }, [dashboard?.widgets]);

  // Reorder logic
  const getDashboardWidgetIndex = useCallback(
    (widget) => {
      return (dashboard?.widgets ?? []).findIndex(
        (w) => w.name === widget.name,
      );
    },
    [dashboard?.widgets],
  );
  const getIndex = useCallback(
    (widget) => {
      return widgetList.findIndex(
        (w) => w.name === widget.name,
      );
    },
    [widgetList],
  );

  const handleReorderHover = useCallback(
    (widget, hoverIndex) => {
      const originalIndex = getIndex(widget);

      if (originalIndex !== -1 && originalIndex !== hoverIndex) {
        setWidgetList(
          update(widgetList, {
            $splice: [
              [originalIndex, 1],
              [hoverIndex, 0, widget],
            ],
          }),
        );
      }
    },
    [getIndex, widgetList],
  );
  const handleReorder = useCallback((widget, hoverIndex) => {
    const originalIndex = getDashboardWidgetIndex(widget);

    arrayHelpersRef.current.move(
      originalIndex,
      hoverIndex,
    );
  }, [getDashboardWidgetIndex]);

  const handleRemove = useCallback((widget) => {
    const originalIndex = getDashboardWidgetIndex(widget);

    arrayHelpersRef.current.remove(originalIndex);
  }, [getDashboardWidgetIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`sm:block flex ${className}`}>
        {currentEditUserWidget && (
          <Modal>
            <WidgetSettings
              user={currentUser}
              users={users}
              settingsInfo={currentEditUserWidget.widgetInfo}
              widgetIndex={currentEditUserWidget.index}
              onClose={() => setCurrentEditUserWidget(null)}
              onApply={() => setCurrentEditUserWidget(null)}
            />
          </Modal>
        )}
        <div
          className="sm:w-full w-1/2 flex flex-wrap content-start my-2">
          {widgets.map((w) => (
            <DraggableCard
              key={w.name}
              widgetSettingInfo={w}
              addWidget={addWidget}
            />
          ))}
        </div>
        <div className="flex flex-col sm:w-full w-1/2 text-xs">
          <FieldArray
            name="dashboard.widgets"
            render={(arrayHelpers) => {
              arrayHelpersRef.current = arrayHelpers;
              return (
                <Droppable
                  className="flex flex-col flex-1"
                >
                  <div
                    className="flex flex-col gap-y-8 flex-1 py-4"
                    style={{ backgroundImage: `url(${Background})` }}
                  >
                    { widgetList.length > 0 ? 
                      <div>
                        {widgetList.map((w, index) => {
                          return (
                            <WidgetPreview
                              key={w.name}
                              widget={w}
                              widgetType={widgets.find(
                                (widget) =>
                                  widget.name.toLowerCase() ===
                                  w.name.toLowerCase()
                              )}
                              identity={{
                                id: currentUser.id,
                                tenantId: currentUser.tenantId,
                                name: currentUser.name,
                                userName: currentUser.name,
                                email: currentUser.email,
                              }}
                              onEditSettings={(widget, widgetType) => {
                                const payload = {
                                  widgetInfo: widgetType,
                                  index: index,
                                  userWidget: widget,
                                };
                                setCurrentEditUserWidget(payload);
                              }}
                              getIndex={getIndex}
                              handleRemove={handleRemove}
                              handleReorderHover={handleReorderHover}
                              handleReorder={handleReorder}
                            />
                          );
                        })}
                      </div>
                    :
                      <div className="text-center flex flex-col justify-center align-middle h-1/3">
                        <p className="text-7xl font-bold text-white2 mb-3">
                          No Widgets
                        </p>
                        <p className="text-3xl text-white2">
                          Drag a widget here to personalise your homepage
                        </p>
                      </div>
                    }
                  </div>
                </Droppable>
              )
            }} />
        </div>
      </div>
    </DndProvider>
  );
};
