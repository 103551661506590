import config from "config";
import TakePhoto from "./TakePhoto";
import RemoveRecognition from "./RemoveRecognition";
import "./FacialRecognition.css";

interface FacialRecognitionProps {
  userId: string;
  tenantId?: string;
}
export const FacialRecognition = ({ tenantId, userId }: FacialRecognitionProps) => {
  // TODO: Add listeners to form buttons and connect to backend
  // PBI - https://dev.azure.com/ssw/SSW.SophieHub/_workitems/edit/69908
  const handleUpload = (file) => fetch(`${config.SophieApiUrl}/api/Face/uploadpersonface?tenantId=${tenantId}&userId=${userId}`, {
    method: 'POST',
    body: file,
  })
    .then(response => response.ok);

  const handleUploadBase64 = (base64) => {
    const [, parsedBase64] = base64.split(',');

    return fetch(`${config.SophieApiUrl}/api/Face/uploadpersonfacebase64?tenantId=${tenantId}&userId=${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parsedBase64),
    }).then(response => response.ok);
  }

  const handleUploadUrl = (url) => fetch(`${config.SophieApiUrl}/api/Face/uploadpersonfaceurl?tenantId=${tenantId}&userId=${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(url),
  }).then(response => response.ok);

  const handleRemove = () => fetch(`${config.SophieApiUrl}/api/Face/clearallpersonfaces?tenantId=${tenantId}&userId=${userId}`, {
    method: 'DELETE',
  })
    .then(response => response.ok);

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-row justify-between gap-x-8">
        <TakePhoto disabled={!userId} upload={handleUploadBase64} uploadFile={handleUpload} uploadUrl={handleUploadUrl} />
        <RemoveRecognition disabled={!userId} remove={handleRemove} />
      </div>
      {/* <div className="flex flex-row gap-x-8">
        <UploadImage disabled={!userId} upload={handleUpload} />
        <FetchImage disabled={!userId} upload={handleUploadUrl} />
      </div> */}
    </div>
  );
};