import React, { useEffect, useState } from "react";
import { TenantUser } from "models";
import { Avatar } from "components";

interface UserSelectorProps {
  identities: TenantUser[];
  selectedIdentity?: TenantUser;
  selectUser: any;
}

interface UserProps {
  identity: TenantUser;
  isSelected: boolean;
}

export const UserWidget = (
  props: UserProps & React.HTMLAttributes<HTMLElement>
) => {
  const { isSelected, identity, className, ...rest } = props;

  const [cn, setCn] = useState("w-10 h-10");
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const temp = `${className} ${isSelected ? "w-12 h-12" : "w-10 h-10"}`;
    setCn(temp);
  }, [isSelected, className]);

  useEffect(() => {
    setUrl(identity.avatarUrl);
  }, [identity.avatarUrl]);

  return <Avatar url={url} name={identity.name} className={cn} {...rest} />;
};

export const UsersSelectorWidget = ({ identities, selectedIdentity, selectUser }: UserSelectorProps) => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (!identities || identities.length === 0) {
      setUsers(null);
      return;
    }

    const children = identities.map(identity => {
      const isSelected =
        selectedIdentity &&
        identity.facePersonId === selectedIdentity.facePersonId;
      return (
        <UserWidget
          key={identity.id}
          identity={identity}
          isSelected={isSelected}
          onClick={() => selectUser(identity)}
          className="bg-white0 my-auto"
        />
      );
    });

    setUsers(children);
  }, [identities, selectedIdentity, selectUser]);

  return <div className="flex flex-row">{users}</div>;
};