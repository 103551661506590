import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { sortBy, unionBy } from "lodash-es";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {
  GetIdentityFromLocalStorage,
  RemoveIdentityFromLocalStorage,
  GetIdentityByUsernameOrEmail,
} from "services/CognitiveServices.service";
import { useDebouncedCallback } from "hooks";
import config from "config";
import Dashboard from "Features/Dashboard";
import { Clock, UsersSelectorWidget } from "components";
import { ReactComponent as SSWLogo } from "assets/images/SSW-logo.svg";
import { Visitor, HubLocation, TenantUser } from "models";
import "./home.css";
import Login from "../../components/Login";
import { MdMenu } from "react-icons/md";

interface MessageData {
  identities: TenantUser[];
  unrecognizedIdentities: number;
  cameraVersion: string;
  requestId: string;
  eventId: string;
  timestamp: string;
  cameraLocation: string;
}

interface HomeParams {
  tenantId: string;
  locationId: string;
  email?: string;
}

function Home() {
  const params = useParams<keyof HomeParams>();

  const [allIdentities, setAllIdentities] = useState<TenantUser[]>([]);
  const [selectedIdentity, setSelectedIdentity] = useState<TenantUser>(null);
  const [qrCode, setQRCode] = useState<string>(null);

  const [timezone] = useState(moment.tz.guess());
  const [currentApiVersion, setCurrentApiVersion] = useState(null);
  const [hubConnection, setHubConnection] = useState(null);
  const [apiUrl] = useState(config.SophieApiUrl);
  const [location, setLocation] = useState<HubLocation>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [users, setUsers] = useState(null);
  const [manualLogin, setManualLogin] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  // 1000 * 60 * 5 === 5 minutes
  const debouncedActivity = useDebouncedCallback(() => {
    // Inactivity logout only applies for user logged in via facial regconition (i.e. kiosk)
    if (selectedIdentity && !manualLogin) {
      console.log("logging user out due to inactivity");
      logout();
    }
  }, 1000 * 60 * 5);

  const getUserProfile = useCallback((tenantId: string, locationId: string, username: string) => {
    if (username) {
      GetIdentityByUsernameOrEmail(tenantId, locationId, username).then((user) => {
        if (user) {
          addUsers({ identities: [user] } as MessageData);
          setManualLogin(true);
          if (!locationId) {
            setLocation({ locationId: user.defaultLocation, tenantId });
          }
        }
      });
      debouncedActivity();
    }
  }, []);

  //DidMount
  useEffect(() => {
    const { email, tenantId, locationId } = params;
    if (email) {
      getUserProfile(tenantId, locationId, email);
    }

    if (tenantId) {
      fetch(`${config.SophieApiUrl}/api/Tenant/${tenantId}/users`)
        .then((response) => (response.status === 200 ? response.json() : null))
        .then((result) => {
          if (result) {
            setUsers(result);
          }
        });
    }

    const user = GetIdentityFromLocalStorage();
    if (user) {
      console.log("user in local");
      addUsers({ identities: [user] } as MessageData);
      if (!locationId) {
        setLocation({ locationId: user.defaultLocation, tenantId });
      }
      setManualLogin(true);
    }

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/api/hubs/cameras`)
      .build();

    hubConnection.on("FacesDetected", (data) => {
      const user = GetIdentityFromLocalStorage();

      // If user is in demo mode, don't change user
      if (user?.["username"] === "demo") {
        return;
      }

      console.log("Faces updated", data);
      const message = JSON.parse(data) as MessageData;

      if (message.identities.length > 0) {
        const newVals = sortBy([...message.identities], ["userId"]).map((x) => {
          return {
            id: x.userId,
            name: x.name,
            userName: x.userData.nickname,
            tenantId: params.tenantId,
            email: x.userData.email,
            facePersonId: x.personId,
            avatarUrl: x.userData.pictureUrl,
            defaultLocation: x.defaultLocation,
          };
        });

        setAllIdentities(newVals);
      } else if (message.unrecognizedIdentities > 0) {
        fetch(
          `${apiUrl}/api/ExpectedVisitor/${params.tenantId}/${params.locationId}`
        )
          .then((response) =>
            response.status === 200 ? response.json() : null
          )
          .then((result) => {
            if (result) {
              const v = result as Visitor;

              const identity = {
                id: "registeredVisitor",
                name: v.name,
                userName: v.contactName,
                tenantId: params.tenantId,
              } as TenantUser;
              setAllIdentities([identity]);
            }
          });
      }
    });

    hubConnection.onclose((error) => {
      console.log(`Dropped SignalR connection ${JSON.stringify(error)}`);
      if (error) initialiseServerConnection();
    });

    setHubConnection(hubConnection);

    initialiseServerConnection();
    if (location) {
      return () => LeaveCameraLocation(location);
    }
  }, [params, getUserProfile]);

  useEffect(() => {
    connectToServer();
  }, [hubConnection]);

  useEffect(() => {
    debouncedActivity();
  }, [selectedIdentity]);

  useEffect(() => {
    if (!selectedIdentity) {
      setSelectedIdentity(allIdentities[0]);
    } else {
      // TODO: Remove personId
      const selected = allIdentities.find(
        (i) =>
          (!!i.id && i.id === selectedIdentity.id) ||
          (!!i.facePersonId && i.facePersonId === selectedIdentity.facePersonId)
      );

      if (!selected) {
        setSelectedIdentity(allIdentities[0]);
      }
    }
  }, [allIdentities]);

  //screenNameChanged
  useEffect(() => {
    const { locationId, tenantId } = params;
    const newLocation = { locationId, tenantId };
    setLocation(newLocation);
    if (hubConnection) {
      JoinCameraLocation(newLocation);
      return () => LeaveCameraLocation(newLocation);
    }
    fetch(
      `${config.SophieApiUrl
      }/api/Tenant/${tenantId}/locations/qrcode?location=${locationId ? locationId : ""
      }`
    )
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          setQRCode(result);
        }
      });
  }, [params]);

  const connectToServer = () => {
    if (!!hubConnection && location) {
      hubConnection.start().then(() => {
        console.log("SignalR connection initiated");
        JoinCameraLocation(location);
      });
    }
  };

  const JoinCameraLocation = (location: HubLocation) => {
    console.log(
      `Subscribing to camera ${location.locationId}[${location.tenantId}]`
    );
    hubConnection.invoke(
      "JoinCameraLocation",
      location.tenantId,
      location.locationId
    );
  };

  const LeaveCameraLocation = (location: HubLocation) => {
    console.log(
      `Unsubscribing from camera ${location.locationId}[${location.tenantId}]`
    );
    hubConnection.invoke(
      "LeaveCameraLocation",
      location.tenantId,
      location.locationId
    );
  };

  const initialiseServerConnection = () => {
    // First check current Server version
    fetch(`${apiUrl}/api/Environment`)
      .then((response) => response.json())
      .then((data) => {
        if (!currentApiVersion) {
          setCurrentApiVersion(data.version);
        } else if (currentApiVersion !== data.version) {
          // If stale refresh browser
          window.location.reload();
        }

        //Otherwise attempt to reconnect
        connectToServer();
      });
  };

  const login = useCallback((email: string) => {
    const { locationId, tenantId } = params;

    if (selectedIdentity) {
      logout();
    }
    getUserProfile(tenantId, locationId, email);
    setShowMenu(false);
    localStorage.setItem("collapsed", JSON.stringify([]));
    // Stop screen sync with the kiosk
    LeaveCameraLocation(location);
  }, [params]);

  const logout = () => {
    const identities = selectedIdentity
      ? allIdentities.filter((x) => x.id !== selectedIdentity.id)
      : [];
    const identity = {
      id: "registeredVisitor",
      name: "",
      userName: "",
      tenantId: params.tenantId,
    } as TenantUser;
    setSelectedIdentity(identity);
    setAllIdentities(identities);
    RemoveIdentityFromLocalStorage();
    setShowMenu(false);

    // Resume screen sync with the kiosk
    JoinCameraLocation(location);
  };

  const addUsers = (message: MessageData) => {
    const currentUsers = [...allIdentities];
    const users = unionBy(currentUsers, message.identities, (i) => i.id);
    const selectedUser = selectedIdentity ? selectedIdentity : users[0];

    setAllIdentities(users);
    setSelectedIdentity(selectedUser);
  };

  const menuRef = useRef(null);
  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.title = "Home";
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  });

  return (
    <div
      className="flex flex-col"
      onKeyUp={debouncedActivity}
      onTouchEnd={debouncedActivity}
      onClick={debouncedActivity}
    >
      <div className="sticky top-0 z-50 flex flex-row items-center h-20 px-5 bg-black0 text-white2 Header">
        <div className="flex w-1/3">
          {/* TODO : Replace with Tenant Logo*/}
          <SSWLogo className="w-20 mr-5 my-auto" />
        </div>
        <Clock interval={1000} timezone={timezone} />
        <div className="flex flex-row-reverse my-auto w-1/3 pt-1">
          <div ref={menuRef} className="my-auto ml-4">
            <button onClick={toggleMenu}>
              <MdMenu size={32} />
            </button>
            {showMenu && (
              <Login
                identity={selectedIdentity}
                onLogin={login}
                onLogout={logout}
                users={users}
                qrCode={qrCode}
              />
            )}
          </div>
          <UsersSelectorWidget
            identities={allIdentities}
            selectedIdentity={selectedIdentity}
            selectUser={(user) => setSelectedIdentity(user)}
          />
        </div>
      </div>
      <Dashboard currentIdentity={selectedIdentity} locationInfo={location} />
    </div>
  );
}

export default Home;
