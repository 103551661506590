import { BacklogState, BacklogType } from "../models/enums";
import { users, addDays } from './testDataUtility';

// Product Backlog Widget
const testBacklogData = [
  {
    id: "1",
    title: "💄User Profiles - UX Experience",
    state: BacklogState.committed,
    effort: "4",
    assignedTo: users[3],
    type: BacklogType.backlogItem,
    description: "Dummy description",
    acceptanceCriteria: "Dummy acceptance criteria",
    url: "123",
  },
  {
    id: "2",
    title: "🐛 Home page widgets should stay the same until refreshed",
    state: BacklogState.committed,
    effort: "4",
    assignedTo: users[2],
    type: BacklogType.bug,
    description: "Dummy description",
    acceptanceCriteria: "Dummy acceptance criteria",
    url: "123",
  },
  {
    id: "3",
    title: "👯 Add a demo button and a dummy user",
    state: BacklogState.committed,
    effort: "16",
    assignedTo: users[1],
    type: BacklogType.backlogItem,
    description: "Dummy description",
    acceptanceCriteria: "Dummy acceptance criteria",
    url: "123",
  },
  {
    id: "4",
    title: "🎞️ Make a Done Video for SophieHub",
    state: BacklogState.committed,
    effort: "4",
    assignedTo: users[3],
    type: BacklogType.backlogItem,
    description: "Dummy description",
    acceptanceCriteria: "Dummy acceptance criteria",
    url: "123",
  },
  {
    id: "5",
    title: "🎞✨ Add banner: \"Coming Soon: New .NET MAUI UI 🏄‍♀️\"",
    state: BacklogState.committed,
    effort: "1",
    assignedTo: users[0],
    type: BacklogType.backlogItem,
    description: "Dummy description",
    acceptanceCriteria: "Dummy acceptance criteria",
    url: "123",
  }
];

export const testSprintData = {
  workItems: testBacklogData,
  id: "123",
  name: "Sprint 22",
  startDate: addDays(0, 0, 0),
  endDate: addDays(7, 0, 0)
};


