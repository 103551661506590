import React, { useState, useEffect, useCallback, useContext } from "react";
import { UserConnection } from "models";
import config from "config";
import { ReactComponent as DeleteIcon } from "assets/images/circle-xmark.svg";
import { ReactComponent as PlusIcon } from "assets/images/plus.svg";
import Modal from "components/Modal/Modal";
import { toastSuccess } from "services/ToastService.service";

interface UserConnectionsProps {
  formConnections: UserConnection[];
  connections: UserConnection[];
  userProfileId: string;
  onConnectionChange: (connections) => void;
}

export const UserConnections = (props: UserConnectionsProps) => {
  const { userProfileId, onConnectionChange, connections } = props;
  const [toDelete, setToDelete] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (connections) {
      onConnectionChange(connections);
    }
  }, [connections]); 

  const reloadConnections = useCallback(() => {
    fetch(
      `${config.SophieApiUrl}/api/UserProfiles/${userProfileId}/connections`
    )
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          onConnectionChange(result);
        }
      });
  }, [userProfileId, onConnectionChange]);

  const handleRemoveIndex = useCallback((index) => {
    onConnectionChange((prevValues => [
      ...prevValues.slice(0, index),
      ...prevValues.slice(index + 1)
    ]))
  }, []);

  const handleRemoveConnection = (index) => {
    // remove unsaved connection
    if (connections[index].id === null) {
      handleRemoveIndex(index);

      return;
    }

    fetch(
      `${config.SophieApiUrl}/api/UserProfiles/${userProfileId}/connections/${connections[index].id}`,
      {
        method: "delete",
      }
    ).then((response) => {
      if (response.status === 200) {
        reloadConnections();

        toastSuccess(`User connection ${connections[index].name} was successfully removed.`, `remove-connection-${connections[index].name}`);
      } else {
        console.log("failed removing connection");
      }
    });
  };

  const renderInput = (index, key, value) => (
    <input
      className="user-section_input"
      type="text"
      name={key}
      value={value}
      onChange={handleChange.bind(null, index)}
    />
  )

  const handleChange = (index, event) => {
    onConnectionChange(prevValues => {
      const newValues = prevValues.slice();
      newValues[index][event.target.name] = event.target.value;

      return newValues;
    });
  }

  const handleNewConnection = () => {
    onConnectionChange(prevValues => [...prevValues, {
      id: null,
      name: "",
      serviceType: "",
      accessToken: "",
    }]);
  };

  return (
    <div>
      {open && (
        <Modal>
          <div className="flex flex-col p-4 bg-white1 rounded">
            <div className="flex flex-col p-4">
              <p className="text-xl">Are you sure you want to delete this connection?</p>
            </div>
            <div className="flex justify-center gap-x-8 mt-4">
              <button type="button" className="facial-recognition-button bg-red1 text-white1 text-xl" onClick={() => {
                handleRemoveConnection(toDelete); 
                setToDelete(null);
                setOpen(false)}}
              >Delete</button> 
              <button type="button" className="facial-recognition-button DarkBtn text-xl" onClick={() => {setOpen(false); setToDelete(null)}}>Cancel</button>
            </div>
          </div>
        </Modal>
      )}

      <div className="w-full flex flex-row text-left mb-4 sm:hidden">
        <div className="w-3/12 user-section_input-label">Name</div>
        <div className="w-3/12 user-section_input-label">Service</div>
        <div className="w-5/12 user-section_input-label">Access Token</div>
      </div>
      <div>
        {connections.map((connection, index) => (
          <div
            className="w-full flex flex-row text-left mb-4 sm:flex-col sm:gap-y-4"
            key={`connection-${index}`}
          >
            <div className="w-3/12 pr-8 sm:w-full sm:pr-0">
              {renderInput(index, 'name', connection.name)}
            </div>
            <div className="w-3/12 pr-8 sm:w-full sm:pr-0">
              {renderInput(index, 'serviceType', connection.serviceType)}
            </div>
            <div className="w-5/12 pr-8 sm:w-full sm:pr-0">
              {renderInput(index, 'accessToken', connection.accessToken)}
            </div>
            <div className="w-1/12 sm:w-full my-auto flex">
              <button
                className="user-section_icon-btn text-red1"
                onClick={() => {
                  setToDelete(index)
                  setOpen(true)
                }}
                type="button"
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div>
        <button className="user-section_btn btn-dark-grey text-xl" type="button" onClick={handleNewConnection}>
          <PlusIcon /> Add Connection
        </button>
      </div>
    </div>
  );
};

const invalidConnection = (inputField) => {
  return (
    inputField.accessToken === "" ||
    inputField.name === "" ||
    inputField.serviceType === ""
  );
};
