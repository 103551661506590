import React from "react";
import { capitalize } from "lodash-es";
import { WeatherInfo } from "../../../models";

interface CurrentWeatherProps {
  weather: WeatherInfo;
  location: string;
}

interface WeatherDisplayProps {
  location: string;
  description: string;
  icon: string;
  avgTemp: number;
  minTemp: number;
  maxTemp: number;
}

const NoWeather = (): JSX.Element => {
  return <p>Loading weather data</p>;
};

const WeatherDisplay = (props: WeatherDisplayProps): JSX.Element => {
  const { location, description, icon, avgTemp, minTemp, maxTemp } = props;
  return (
    <div className="py-2">
      <div className="font-bold text-xl">{capitalize(location)}</div>
      <div className="current-weather">
        <img
          alt={description}
          className="weather-icon"
          src={`http://openweathermap.org/img/wn/${icon}@2x.png`}
        />
        <span className="temperature">{avgTemp.toFixed(0)}°</span>
      </div>
      <p className="mb-0 min-max-temp text-xl">
        {minTemp.toFixed(0)}° / {maxTemp.toFixed(0)}°{" "}
      </p>
      <p className="mb-0 text-xl">{description}</p>
    </div>
  );
};

const CurrentWeather = (props: CurrentWeatherProps): any => {
  const { weather, location } = props;

  return (
    <div>
      <div className="text-2xl font-bold">Current weather</div>
      {!weather && <NoWeather />}
      {weather && (
        <WeatherDisplay
          location={location}
          description={weather.weatherDescription}
          icon={weather.icon}
          avgTemp={weather.avgTemp}
          minTemp={weather.minTemp}
          maxTemp={weather.maxTemp}
        />
      )}
    </div>
  );
};

export default CurrentWeather;
