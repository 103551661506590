interface Config {
  SophieApiUrl: string;
  DialogFlowApiKey: string;
  DefaultTimezone: string;
  MobileUser: string;
  BuildVersion: string;
}

const dev = {
  SophieApiUrl: "https://sswsophiehubapi-dev.azurewebsites.net"
};

const prod = {
  SophieApiUrl: "https://hub-api.sswsophie.com"
};

const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : {};

export default {
  SophieApiUrl: "http://localhost:18628",
  DialogFlowApiKey: process.env.REACT_APP_DIALOG_FLOW_API_KEY,
  DefaultTimezone: "Australia/Sydney",
  MobileUser: "GregHarris@ssw.com.au",
  BuildVersion: process.env.REACT_APP_BUILD_VERSION,
  ...config
} as Config;
