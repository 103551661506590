import React from "react";
import { useField, useFormikContext } from "formik";
import { TenantUser, SettingsInfo } from "models";
import SettingsField from "../SettingsField/SettingsField";
import Select from "react-select";

interface MyTeamSettingsProps {
  widgetIndex: number;
  users: TenantUser[];
  settings: SettingsInfo[];
}
const MyTeamSettings = (props: MyTeamSettingsProps) => {
  const { widgetIndex, users, settings } = props;
  const userList = users.map((x) => {
    return { value: x.email, label: x.name };
  });

  const { setFieldValue } = useFormikContext();
  const [teamMatesField] = useField(
    `dashboard.widgets[${widgetIndex}].settings.teamMates`
  );

  let initialValues;
  if (teamMatesField.value) {
    const emailList = teamMatesField.value.split(";");
    initialValues = userList.filter((x) => emailList.indexOf(x.value) !== -1);
  }

  const handleChange = (selectedOption) => {
    setFieldValue(
      `dashboard.widgets[${widgetIndex}].settings.teamMates`,
      selectedOption ? selectedOption.map((x) => x.value).join(";") : ""
    );
  };

  return (
    <>
      <SettingsField
        label="Heading"
        type="text"
        widgetIndex={widgetIndex}
        defaultValue={settings["heading"]}
        name={`dashboard.widgets[${widgetIndex}].settings.heading`}
      />

      <SettingsField
        label="Icon"
        type="text"
        widgetIndex={widgetIndex}
        defaultValue={settings["icon"]}
        name={`dashboard.widgets[${widgetIndex}].settings.icon`}
      />

      <div className="flex flex-row my-6 items-center">
        <label className="text-black w-1/3 text-sm">Team mates</label>
        <Select
          closeMenuOnSelect={true}
          isMulti
          options={userList}
          defaultValue={initialValues}
          className="w-2/3"
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default MyTeamSettings;
