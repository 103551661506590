import { toast } from "react-toastify";

const toastTemplate: object = {
  position: "top-right",
  autoClose: 2000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const toastSuccess = (message: string, id: string) => {
  toast.success(message, {
    ...toastTemplate,
    toastId: id,
  });
}

export const toastError = (message: string, id: string) => { 
  toast.error(message, {
    ...toastTemplate,
    toastId: id,
  });
}