import React, { useMemo, useRef, useState } from "react";
import { Widget, WidgetSettingsInfo, TenantUser } from "models";
import { WidgetComponent } from "../../../Dashboard/components";
import { ReactComponent as SettingsIcon } from "assets/images/setting.svg";
import { ReactComponent as BinIcon } from "assets/images/bin.svg";
import { ReactComponent as MoveIcon } from "assets/images/move.svg";
// import { ReactComponent as DuplicateIcon } from "assets/images/duplicate.svg";
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord } from 'dnd-core';
interface WidgetPreviewProps {
  widget: Widget;
  widgetType: WidgetSettingsInfo;
  identity: TenantUser;
  onEditSettings: (widget: Widget, widgetType: WidgetSettingsInfo) => void;
  handleReorderHover?: (widget: Widget, hoverIndex: number) => void;
  handleReorder?: (widget: Widget, hoverIndex: number) => void;
  handleRemove: (widget: Widget) => void;
  getIndex: (widget: Widget) => number;
  className?: string;
}

const WidgetPreview = ({
  widget,
  widgetType,
  identity,
  onEditSettings,
  handleReorderHover,
  handleReorder,
  handleRemove,
  getIndex,
  className,
}: WidgetPreviewProps) => {
  const originalIndex = useMemo(() => getIndex(widget), []);
  const ref = useRef<HTMLDivElement>(null)
  const [isHover, setIsHover] = useState(false);

  const [, drop] = useDrop(() => ({
    accept: ['WIDGET_CARD', 'WIDGET_PREVIEW'],
    hover({ originalIndex, ...item }: any, monitor) {
      if (monitor.getItemType() === 'WIDGET_CARD') {
        return;
      }
      
      if (!ref.current) {
        return
      }

      // Note: not sure why originalIndex doesn't work, need to getIndex again from the list
      const dragIndex = getIndex(item);
      const hoverIndex = getIndex(widget);

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      handleReorderHover && handleReorderHover(item, hoverIndex);
    },
    drop(_, monitor) {
      const hoverIndex = getIndex(widget);

      switch (monitor.getItemType()) {
        case 'WIDGET_CARD':
          return {
            type: 'INSERT',
            hoverIndex,
          }
        case 'WIDGET_PREVIEW':
          return {
            type: 'MOVE',
            hoverIndex,
          }
        default:
      }
    }
  }), [getIndex, handleReorderHover, handleReorder]);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'WIDGET_PREVIEW',
    item: { ...widget, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: ({ originalIndex, ...draggedWidget }: any, monitor) => {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        const { type, hoverIndex } = monitor.getDropResult<{ type: string; hoverIndex: number }>();

        switch (type) {
          // reorder source list
          case 'MOVE':
            handleReorder && handleReorder(draggedWidget, hoverIndex);
            break;
          default:
        }
      } else {
        // restore order when dropped outside
        handleReorderHover && handleReorderHover(draggedWidget, originalIndex);
      }
    },
  }), [widget, originalIndex, handleReorder, handleReorderHover]);

  dragPreview(drop(ref));

  return (
    <div ref={ref}
      className={`${className ?? ''} relative w-full select-none px-6 py-4`}
      style={{ opacity: isDragging ? 0 : 1 }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>

      <WidgetComponent
        widget={widget}
        name={identity.name}
        identity={identity}
        isPreview={true}
        help={{
          widgetName: identity.name,
          description: ["The “My Team” widget allows an active display of who is on your team, what they’re doing, where they are, when their estimated arrival is, and when their daily scrum is.", "Below this, buttons labelled by office location can be clicked to view all employees located there."],
          steps: ["Navigate to the 'My Team' widget settings via the user settings", "Add one or more users", "Save & Exit"] 
        }}
      />
      {isHover && (
        <div
          className="h-full bg-black0 absolute top-0 left-0 text-white2 flex flex-row justify-around items-center
            w-full"
          style={{
            backdropFilter: "blur(2px)",
            zIndex: "20"
          }}
        >
          <div ref={drag}><MoveIcon className="text-white2 cursor-move" /></div>
          {/* <DuplicateIcon className="cursor-pointer text-white2" /> */}
          <SettingsIcon
            className={`${widgetType?.settings?.length ? "text-white2 cursor-pointer" : "text-black2"
              }`}
            onClick={() => widgetType?.settings?.length && onEditSettings(widget, widgetType)}
          />
          <BinIcon
            onClick={() => handleRemove(widget)}
            className="cursor-pointer text-white2"
          />
        </div>
      )
      }
    </div >
  )
};

export default WidgetPreview;
