import { TenantUser } from "../models";
import config from "../config";

export const LocalStorageKeys = {
  IDENTITY: "user",
  DATE_CACHED: "date-cached"
};

// invalidation timeout in ms
// 1 min // 1 hour // 1 day
const ID_INVALID = 1000 * 60 * 60 * 24 * 7;

export const GetIdentityByUsernameOrEmail = async (tenantId: string, locationId: string, email: string): Promise<TenantUser> => {
  return fetch(`${config.SophieApiUrl}/api/userProfiles?tenantId=${tenantId}&locationId=${locationId ? locationId : ''}&nameOrEmail=${email}`)
    .then(response => {
      return response.ok ? response.json() : null;
    })
    .then(json => {
      const userProfile: TenantUser = json;
      if (userProfile) {
        SaveIdentityInLocalStorage(userProfile);
      }
      return userProfile;
    });
};

export const GetIdentityFromLocalStorage = (): TenantUser => {
  const id = localStorage.getItem(LocalStorageKeys.IDENTITY);
  return id && LocalStorageValid(LocalStorageKeys.IDENTITY, ID_INVALID)
    ? (JSON.parse(id) as TenantUser)
    : null;
};

export const SaveIdentityInLocalStorage = (userProfile: TenantUser): void => {
  localStorage.setItem(LocalStorageKeys.IDENTITY, JSON.stringify(userProfile));
  localStorage.setItem(
    `${LocalStorageKeys.IDENTITY}-${LocalStorageKeys.DATE_CACHED}`,
    JSON.stringify(new Date())
  );
};

export const RemoveIdentityFromLocalStorage = (): void => {
  localStorage.removeItem(LocalStorageKeys.IDENTITY);
};

export const LocalStorageValid = (
  key: string,
  cacheValidTimeout: number
): boolean => {
  const data = localStorage.getItem(`${key}-${LocalStorageKeys.DATE_CACHED}`);
  if (data) {
    const shouldFetch =
      new Date().getTime() - new Date(JSON.parse(data)).getTime() <
      cacheValidTimeout;
    if (!shouldFetch) {
      localStorage.removeItem(`${key}-${LocalStorageKeys.DATE_CACHED}`);
      localStorage.removeItem(key);
    }
    return shouldFetch;
  }
  return true;
};
