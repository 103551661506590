export const BacklogType = {
  backlogItem: "Product Backlog Item",
  bug: "Bug",
  userStory: "User Story",
};

export const BacklogState = {
  committed: "Committed",
  done: "Done",
  closed: "Closed",
};