import React, { useEffect, useState } from "react";
import Modal from "components/Modal/Modal";
import { MdClose } from "react-icons/md";

export interface WidgetHelpPopUpProps {
  widgetName: string,
  description: Array<string>;
  steps: Array<string>;
  closeModal?: any;
}

export const WidgetHelpPopUp = (props: WidgetHelpPopUpProps) => {
  const { widgetName, description, steps, closeModal } = props;

  return (
    <Modal>
      <div className="flex flex-col bg-white1 rounded w-[60vw]">
        <div className="flex rounded-t justify-between p-4 items-center mb-4 bg-red1">
          <span className="text-2xl text-white1">{widgetName}</span>
          <button onClick={() => closeModal(false)}>
            <MdClose color="white" size={32} />
          </button>
        </div>
        <div className="px-4 pb-4 text-xl">
          { description.map(paragraph => <><p>{paragraph}</p> <br /></>)}

          { steps.length > 0 &&
            <>
              <span className="font-medium text-xl">How to use:</span>
              <ol className="list-decimal list-inside">
                { steps.map(step => <li className="ml-2" dangerouslySetInnerHTML={{ __html: step }} />)}
              </ol>
            </>
          }
        </div>
      </div>
    </Modal>
  );
};
