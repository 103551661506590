import React, { useState, useEffect, useCallback } from "react";
import "./greeting.css";

interface GreetingProps {
  name: string;
  customGreeting?: string;
}

const greetings = [
  "How are you today?",
  "You look baller!",
  "Looking good today!",
  "Your smile is contagious.",
  "You look great today.",
  "You're a smart cookie.",
  "I bet you make babies smile.",
  "You have impeccable manners.",
  "I like your style.",
  "You have the best laugh.",
  "I appreciate you.",
  "You are the most perfect you there is.",
  "You are enough.",
  "You're strong.",
  "Your perspective is refreshing.",
  "You light up the room.",
  "You should be proud of yourself.",
  "You're more helpful than you realize.",
  "You have a great sense of humor.",
  "Your kindness is a balm to all who encounter it.",
  "On a scale from 1 to 10, you're an 11.",
  "You are brave.",
  "You are making a difference.",
  "You're like sunshine on a rainy day.",
  "You bring out the best in other people.",
  "Everything would be better if more people were like you.",
  "That color is perfect on you.",
  "Being around you makes everything better.",
  "When you're not afraid to be yourself is when you're most incredible.",
  "Colors seem brighter when you're around.",
  "You're wonderful.",
  "Jokes are funnier when you tell them.",
  "Your hair looks stunning.",
  "You're inspiring.",
  "Our community is better because you're in it.",
  "You have the best ideas.",
  "Everyone gets knocked down sometimes, but you always get back up and keep going.",
  "You're a candle in the darkness.",
  "You're a great example to others.",
  "Being around you is like being on a happy little vacation.",
  "You always know just what to say.",
  "You're always learning new things and trying to better yourself, which is awesome.",
  "You're more fun than bubble wrap.",
  "Who raised you? They deserve a medal for a job well done.",
  "You're great at figuring stuff out.",
  "Your voice is magnificent.",
  "You're like a breath of fresh air.",
  "You're so thoughtful.",
  "Your creative potential seems limitless.",
  "When you make up your mind about something, nothing stands in your way.",
  "You seem to really know who you are.",
  "Any team would be lucky to have you on it.",
  "Babies and small animals probably love you.",
  "There's ordinary, and then there's you.",
  "You're someone's reason to smile.",
  "You're even better than a unicorn, because you're real.",
  "You're really something special.",
  "You're a gift to those around you."
];
export const Greeting = ({ name, customGreeting }: GreetingProps) => {
  const [displayText, setDisplayText] = useState("You look great");
  const [lastComplimentIndex, setLastComplimentIndex] = useState(0);

  const genGreeting = useCallback((greeting) => {
    if (greeting) {
      setDisplayText(greeting);
    }

    let newIndex = lastComplimentIndex;
    while (newIndex === lastComplimentIndex) {
      newIndex = Math.floor(Math.random() * greetings.length);
    }

    setLastComplimentIndex(newIndex);
    setDisplayText(greetings[newIndex]);
  }, [lastComplimentIndex]);

  useEffect(() => {
    genGreeting(customGreeting);
  }, [name, customGreeting]);

  return (
    <div className="sophie-greeting text-center py-4">
      <h2 className="user-name">Hello {name}!</h2>
      <h4 className="message-text"> {displayText} </h4>
    </div>
  );
};
