import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import * as echarts from "echarts";
import { refreshData } from "services/Helpers.service";
import { addDays } from '../../../testData/testDataUtility'
import NoContent from "../../NoContent/NoContent.jsx";

// Test data
import { testDurationSeriesData, testCallCountSeriesData } from "../../../testData/appInsightsTestData";

interface AppInsightsProps {
  appId: string;
  apiKey: string;
  demo?: boolean;
}


const AnalyticsBar = ({ data, xLabel, yLabel }) => {
  const domRef = useRef();

  useEffect(() => {
    const chart = echarts.init(domRef.current);

    chart.setOption({
      grid: {
        containLabel: true,
        top: 40,
        bottom: 30,
        left: 60,
        right: 30,
      },
      title: {
        text: 'Error Occurrences within past 24 hours',
        left: 'center',
        top: 5,
      },
      color: ["#eda66f"],
      tooltip: {
        show: true,
      },
      dataset: {
        dimensions: ['x', 'y'],
        source: data,
      },
      xAxis: {
        name: xLabel, nameLocation: 'middle', nameGap: "30", nameTextStyle: {
          fontWeight: 'bold',
          fontSize: '1rem'
        }, type: 'category', 
        axisLabel: {
          formatter: seriesIndex => seriesIndex.split(':')[0] == processTime(moment(new Date())).split(':')[0] ? `{a|${seriesIndex}}` : seriesIndex,
          rich: {
            a: {
              color: "#cc4141",
              fontWeight: "bold",
            },
          },
        }
      },
      yAxis: {
        name: yLabel, nameLocation: 'middle', nameGap: "40", nameTextStyle: {
          fontWeight: 'bold',
          fontSize: '1rem'
        }, 
      },
      series: [{
        itemStyle: {
          color: seriesIndex => seriesIndex.name.split(':')[0] == processTime(moment(new Date())).split(':')[0] ? 'rgba(255, 173, 177, 1)' : 'rgba(237, 166, 111, 0.7)', 
        },
        type: 'bar',
        encode: {
          tooltip: [1]
        },
      }]
    });

    return () => {
      chart.dispose();
    }
  }, [])

  window.addEventListener('resize', function () {
    if (domRef.current) {
      const chart = echarts.getInstanceByDom(domRef.current);
      chart && chart.resize()
    }
  })

  return <div ref={domRef} className="w-full h-full" />;
}

const AnalyticsLine = ({ data, xLabel, yLabel }) => {
  const domRef = useRef();

  useEffect(() => {
    const chart = echarts.init(domRef.current);

    chart.setOption({
      title: {
        text: 'Response Time within past 24 hours',
        left: 'center',
        top: 5,
      },
      grid: {
        containLabel: true,
        top: 40,
        bottom: 30,
        left: 60,
        right: 30,
      },
      tooltip: {
        show: true,
      },
      dataset: {
        dimensions: ['x', 'y'],
        source: data,
      },
      xAxis: {
        name: xLabel, nameLocation: 'middle', nameGap: "30", nameTextStyle: {
          fontWeight: 'bold',
          fontSize: '1rem'
        }, type: 'category',
        axisLabel: {
        },
      },
      yAxis: {
        name: yLabel, nameLocation: 'middle', nameGap: "45", nameTextStyle: {
          fontWeight: 'bold',
          fontSize: '1rem'
        }, type: 'value'
      },
      series: [{
        type: 'line',
        encode: {
          tooltip: [1]
        },
        lineStyle: {
          color: "#eda66f",
        },
        itemStyle: {
          color: seriesIndex => seriesIndex.name.split(':')[0] == processTime(moment(new Date())).split(':')[0] ? 'rgba(204, 65, 65, 1)' : 'rgba(204, 65, 65, 0.7)',
        },
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0.4)'
          },
          data: [
            [
              {
                xAxis: processTime(moment(addDays(0, null, 0)))
              },
              {
                xAxis: processTime(moment(addDays(0, null, 60)))
              }
            ],
          ]
        }
      }]
    });

    return () => {
      chart.dispose();
    }
  }, [])

  window.addEventListener('resize', function () {
    const chart = echarts.getInstanceByDom(domRef.current);
    chart && chart.resize()
  })

  return <div ref={domRef} className="w-full h-full" />;
}

export const DevOpsAppInsights = ({ appId, apiKey, demo }: AppInsightsProps) => {
  const [durationSeries, setDurationSeries] = useState<any>();
  const [callCountSeries, setcallCountSeries] = useState<any>();

  useEffect(() => {
    if (demo) {
      setDurationSeries(testDurationSeriesData);
      return;
    }

    fetchUsageData(apiKey, appId);
    refreshData(() => fetchUsageData(apiKey, appId));
  }, [appId, apiKey, demo]);

  useEffect(() => {
    if (demo) {
      setcallCountSeries(testCallCountSeriesData);
      return;
    }

    fetchErrorData(apiKey, appId);
    refreshData(() => fetchErrorData(apiKey, appId));
  }, [appId, apiKey, demo]);

  const fetchUsageData = (apiKey: string, appId: string) => {
    const headers = {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    };

    fetch(
      `https://api.applicationinsights.io/v1/apps/${appId}/metrics/requests/duration?timespan=PT24H&interval=PT0.5H`,
      { headers, method: "GET" }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.value?.segments.length > 0) {
          const d1 = data.value.segments[0].start as Date;
          console.log(d1);

          // Segments has to filter out the first array because the key would be duplicating with the current hour
          const segments = (data.value.segments as Array<any>).filter(
            (x, i) => {
              return i !== 0;
            }
          );

          const results = segments.map((s, index) => {
            return {
              x: processTime(moment(s.start)),
              y: s["requests/duration"].avg,
            };
          });

          const d = [
            {
              id: "Usage",
              color: "red",
              data: results,
            },
          ];

          setDurationSeries(d);
        }
      });
  };

  const fetchErrorData = (apiKey: string, appId: string) => {
    const headers = {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    };

    fetch(
      `https://api.applicationinsights.io/v1/apps/${appId}/metrics/exceptions/count?timespan=PT24H&interval=PT0.5H`,
      { headers, method: "GET" }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!!data && !!data.value) {
          const segments = (data.value.segments as Array<any>).filter(
            (x, i) => {
              return i !== 0;
            }
          );

          const results = segments.map((s) => {
            return {
              x: processTime(moment(s.start)),
              y: s["exceptions/count"].sum,
            };
          });

          const d = [
            {
              id: "Errors",
              color: "hsl(337, 70%, 50%)",
              data: results,
            },
          ];
          setcallCountSeries(d);
        }
      });
  };

  return (
    <div className="flex justify-center items-center">
      { (!durationSeries && !callCountSeries) && 
        <NoContent>
          <span>Application Insights not found.</span>
        </NoContent>
      }

      {durationSeries && (
        <div className="w-1/2 h-64">
          <AnalyticsLine
            xLabel="Time"
            yLabel="Duration (ms)"
            data={durationSeries[0].data}
          />
        </div>
      )}

      {callCountSeries && (
        <div className="w-1/2 h-64">
          <AnalyticsBar
            xLabel="Time"
            yLabel="Error Count"
            data={callCountSeries[0].data}
          />
        </div>
      )}
    </div>
  );
};

const processTime = (time) => {
  return (
    time.hours().toString() +
    ":" +
    (time.minutes() === 0 ? "00" : time.minutes().toString())
  );
};
