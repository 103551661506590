import React, { useState, useEffect, useCallback, useMemo } from "react";
import "isomorphic-fetch";
import moment from "moment-timezone";
import config from "config";
import { AppointmentModel } from "models";
import "./appointments-widget.css";
import { ReactComponent as TeamsIcon } from "assets/images/mdi-microsoft-teams.svg";
// Test data
import { testAppointmentData } from "../../../testData/appointmentsTestData";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft, FaCalendarMinus } from 'react-icons/fa';
import { FcCalendar} from 'react-icons/fc';
import NoContent from "../../NoContent/NoContent";

interface AppointmentsProps {
  email: string;
  demo?: boolean;
}

enum AppointmentButton {
  ForwardButtonClicked = "forward",
  BackButtonClicked = "back"
}


const momentFormats = {
  nextDay: "[Tomorrow]",
  nextWeek: "dddd D MMMM YYYY",
  lastDay: "[Yesterday]",
  lastWeek: "[Last] dddd",
  sameElse: "dddd D MMMM YYYY",
  sameDay: "[Today]",
};

const getTeamsMeetingLink = (body: string) => {
  const index = body ? body.indexOf("Join Microsoft Teams Meeting<") : -1;
  if (index !== -1) {
    const str = body.split(/[<>]/);
    return str[1];
  }
  return "";
};

const getBorderClass = (date: string) => {
  return date === "Today"
    ? "leftBorderRed"
    : date === "Tomorrow"
    ? "leftBorderGrey"
    : "leftBorderBlack";
};

function Appointment(props) {
  const {
    appointment,
    date,
  }: { appointment: AppointmentModel; date: string } = props;
  return (
    <div
      className={`flex sm:block w-full team-row pl-8 text-xl ${
        appointment.isCancelled ? "text-grey0 " : ""
      }${getBorderClass(date)}`}
    >
      <div className="w-2/12 sm:w-full text-left py-4 sm:py-2">
        {`${appointment.start} - ${appointment.end}`}
      </div>
      <div className="w-5/12 md:w-4/12 sm:w-full text-left py-4 pr-2 sm:py-2">
        {appointment.subject}
      </div>
      <div className="w-3/12 sm:w-full text-left py-4 pr-2 sm:py-2">
        {appointment.location}
      </div>
      {!appointment.isCancelled && (
        <div className="w-2/12 md:w-3/12 flex flex-col sm:flex-row sm:w-full sm:justify-end py-4 sm:py-2 sm:pr-4">
          {date === "Today" && getTeamsMeetingLink(appointment.body) !== "" && (
            <button
              type="button"
              className="TextBtn text-white1 teams-btn action-btn"
            >
              <a
                href={getTeamsMeetingLink(appointment.body)}
                target="_blank"
                rel="noreferrer"
                className="flex"
              >
                <TeamsIcon className="pr-2 my-auto teams-icon" />
                Join Meeting
              </a>
            </button>
          )}
          {(date !== "Today" ||
            getTeamsMeetingLink(appointment.body) === "") && (
            <button type="button" className="TextBtn action-btn bg-grey2">
              <a
                href={`https://outlook.office.com/calendar/item/${encodeURIComponent(
                  appointment.id
                )}`}
                target="_blank"
                rel="noreferrer"
                className="text-white1 text-xl"
              >
                View
              </a>
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export const AppointmentsWidget = (props: AppointmentsProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loadTime, setLoadTime] = useState(0);
  const [targetedDays, setTargetedDays] = useState(0);

  const fetchAppointments = useCallback((email: string, demo: boolean) => {
    setLoading(true);

    if (demo) {
      setData(aggregateAppointments(testAppointmentData));
      setLoading(false);
      return;
    }

    const start = performance.now();
    let end = null;
    
    fetch(`${config.SophieApiUrl}/Api/Appointments?email=${email}&&targetedDays=${targetedDays}`)
      .then((response) => (response.json()))
      .then((data) => {
        if (data) {
          end = performance.now();
          setLoadTime(Math.ceil((end - start) / 1000));
          setLoading(false);
          setData(aggregateAppointments(data));
        }
      });
  }, [targetedDays]);

  const aggregateAppointments = (appointments: AppointmentModel[]) => {
    const aggregratedList = [];
    appointments.forEach((appointment) => {
      const date = moment(appointment.start).calendar(null, momentFormats);
      const start = moment(appointment.start).format("h:mm a");
      const end = moment(appointment.end).format("h:mm a");
      const newItem = {
        ...appointment,
        start,
        end,
      };
      const existingItem = aggregratedList.find((item) => item.date === date);
      existingItem
        ? existingItem.appointments.push(newItem)
        : aggregratedList.push({ date, appointments: [newItem] });
    });
    return aggregratedList;
  };

  const renderAppointmentTable = (data) => {
    return (
      <div> 
        { data.length === 0 ?
          <NoContent>
          <div className="mx-auto pt-4">
            <span className="text-lg d-inline-flex">
              <div className="icon-size pr-2">
                <FcCalendar />
              </div> 
              No appointments
            </span>
          </div>
          </NoContent>
          :
          <div className="pt-8 p mx-auto text-xl">
            <div>
              {data.map((item, index) => {
                return (
                  <div key={index} className="pb-4">
                    <div className="text-left pl-8 pb-4">
                      <input
                        type="button"
                        value={item.date}
                        className={`TextBtn text-white1 ${getDateClass(
                          item.date
                        )}`}
                      />
                    </div>

                    {item.appointments.map((appointment) => {
                      return (
                        <Appointment
                        key={`${appointment.subject}_${appointment.start}`}
                        appointment={appointment}
                        date={item.date}
                        />
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </div>
        }
      </div>
    );
  };

  const getDateClass = (date: string) => {
    return date === "Today"
      ? "bg-red1"
      : date === "Tomorrow"
      ? "bg-grey2"
      : "bg-black";
  };


const OnNoUpcomingAppointmentClicked = (appointmentButtonClicked:string) =>{
      if(appointmentButtonClicked == AppointmentButton.BackButtonClicked){
      setTargetedDays(targetedDays - 1)
      }
      else{
      setTargetedDays(targetedDays + 1)
      }        
}

const GetTargetedDay = useMemo(() => (
          targetedDays === 0 ? "Today" 
            : targetedDays === -1 ? "Yesterday" 
              : targetedDays === 1 ? "Tomorrow" 
                : moment().add(targetedDays, 'days').format("dddd D MMMM YYYY")
        ), 
  [targetedDays]);

  useEffect(() => {
    fetchAppointments(props.email, props.demo);
        // refreshData(() => fetchAppointments(props.email, isSubscribed));
  }, [fetchAppointments, props.email, props.demo]);

  return (
    <div>
      <div className="appointment-container">
        <div className="flex justify-center items-center pt-4">
            <button className="calendar-arrow mr-4" onClick={() => OnNoUpcomingAppointmentClicked(AppointmentButton.BackButtonClicked)}>
                <FaArrowAltCircleLeft className='bg-red' />
            </button>
            <span className="text-xl">{GetTargetedDay}</span>
            <button className="calendar-arrow ml-4" onClick={() => OnNoUpcomingAppointmentClicked(AppointmentButton.ForwardButtonClicked)}>
                <FaArrowAltCircleRight />
            </button>
        </div>
        {loading && <p className="pt-2">Loading...</p>}
        {!loading && renderAppointmentTable(data)}
      </div>
      <div className="text-left pl-4 pt-2 rounded-b-sm border-white0 text-base">
        Microsoft Office 365 <span className="px-4"></span>
        {loadTime === 0 ? "" : `Load time: ${loadTime}s`}
      </div>
    </div>
  );
};
