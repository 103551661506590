import sanitizeHtml from "sanitize-html";

export const refreshData = (fetchData) => {
  const interval = setInterval(
    fetchData,
    900000 // refetch data every 15 mins
  );
  return () => {
    clearInterval(interval);
  };
};

export const isJsonString = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const sanitizeHtmlStr = (str: string) => {
  return sanitizeHtml(str, {
    allowedTags: ["p", "a", "br"],
    allowedAttributes: {
      a: ["href", "target"],
    },
  });
};
