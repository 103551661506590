import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";

interface ClockProps {
  interval?: number;
  timezone?: string;
}

export const Clock = ({ interval, timezone }: ClockProps) => {
  const [dateString, setDateString] = useState<string>();
  const [timeString, setTimeString] = useState<string>();

  const tzString = timezone ?? "Australia/Sydney";
  
  const updateClock = useCallback(() => {
    const now = moment().tz(tzString);
    const date = now.format("ddd D MMM");
    const time = now.format("H:mm a");

    setDateString(date);
    setTimeString(time);
  }, [tzString]);

  useEffect(() => {
    updateClock();
    const i = setInterval(updateClock, interval ?? 1000);

    return () => {
      clearInterval(i);
    }
  }, [interval, tzString, updateClock]);

  

  return (
    <div className="my-auto w-1/3 text-3xl justify-center sm:text-14 flex sm:block">
      <div className="pr-4 sm:pr-0">{dateString}</div>
      <div>{timeString}</div>
    </div>
  );
};
