import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "config";
import { LocationInfo } from "models";
import { LocationAdmin } from "./components";
import "./admin.css";

interface AdminParams {
  tenantId: string;
  locationId: string;
}

const Admin = () => {
  const params = useParams<keyof AdminParams>();
  const { tenantId } = params;
  const [locations, setLocations] = useState<LocationInfo[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationInfo>(null);
  const [selectedLocationId, setSelectedLocationId] = useState("0");
  //Component Did Mount
  useEffect(() => {
    fetch(`${config.SophieApiUrl}/api/LocationInfo/allLocations?tenantId=${tenantId}`)
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result: LocationInfo[]) => {
        if (result && result.length > 0) {
          setLocations(result);
        } else {
          setLocations([]);
        }
      });
  }, [tenantId]);

  const changeLocation = (event) => {
    const locId = event.target.value as string;
    let loc: LocationInfo;
    if (locId === "") {
      loc = {
        locationId: "",
        expiryDate: null,
        tenantId,
        guestDashboard: "",
        defaultDashboard: "",
        city: "",
        countryId: "",
        apiKey: "",
      };
    } else {
      loc = locations.find((l) => l.locationId === locId);
    }
    setSelectedLocation(loc);
    setSelectedLocationId(loc.locationId);
  };

  const renderLocationsDropdown = (locations: LocationInfo[]) => {
    const dropdownOptions = locations.map((item) => (
      <option key={item.locationId}>{item.locationId}</option>
    ));
    return (
      <select onChange={changeLocation} value={selectedLocationId}>
        <option value="0" disabled>
          Select location...
        </option>
        {dropdownOptions}
        <option value="">Add new location</option>
      </select>
    );
  };

  return (
    <div className="admin">
      <div className="text-24">Administration</div>{" "}
      <p>
        <strong className="pr-2">Locations:</strong>
        {renderLocationsDropdown(locations)}
      </p>
      <h4 className="py-2">Location details</h4>
      {selectedLocation && (
        <LocationAdmin location={selectedLocation} />
      )}
    </div>
  );
};

export default Admin;
