import React, { useEffect, useState } from "react";
import config from "config";
import { Tenant } from "models/Tenant";
import "./landing.css";
import { useNavigate } from "react-router-dom";
import SophieHubLogo from "../../SophieHub_BW.svg";

const Landing = () => {
  const navigate = useNavigate();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenantId, setSelectedTenantId] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${config.SophieApiUrl}/api/Tenant`)
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((result) => {
        if (result) {
          setTenants(result);
        }
      }).finally(() => setLoading(false));
  }, []);

  const selectSite = (event) => {
    setSelectedTenantId(event.target.value);
    navigate(`/sophie/${event.target.value}`);
  };

  return (
    <div className="admin flex items-center justify-center w-full mt-[10%] xl:mt-[25%]">
      <div className="flex items-center flex-col w-1/2">
        <img src={SophieHubLogo} className="mb-5" width={225} />
        <div className="text-6xl text-center color-white">Welcome to <span className="font-black">SSW</span> SophieHub</div>
        <div className="flex items-center justify-center py-2 text-xl xl:mt-[40%] mt-[10%] w-full">
          {loading ?
            <div className="flex justify-center items-center">
              <div className="spinner" role="status">
              </div>
            </div> 
            :
            <select onChange={selectSite} value={selectedTenantId} className="px-3 py-2 w-3/4 xl:w-full">
              <option value="">Select your company</option>
              {tenants.map((tenant, index) => {
                return (
                  <option key={index} value={tenant.id}>
                    {tenant.displayName}
                  </option>
                );
              })}
            </select>
          }
        </div>
      </div>
    </div>
  );
};

export default Landing;
