import React, { useState, useEffect } from "react";
import "isomorphic-fetch";
import config from "config";
import { HubLocation, Widget, TenantUser } from "models";
import { WidgetList } from "./components";

interface DashboardProps {
  currentIdentity?: TenantUser;
  locationInfo: HubLocation;
}

const Dashboard = ({ currentIdentity, locationInfo }: DashboardProps) => {
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [defaultWidgets, setDefaultWidgets] = useState<Widget[]>([]);
  const [currentUser, setCurrentUser] = useState<TenantUser>(null);

  useEffect(() => {
    window.addEventListener("beforeunload", clearStorage);
    return () => {
      window.removeEventListener("beforeunload", clearStorage);
    };
  }, []);

  const clearStorage = (e) => {
    localStorage.setItem("collapsed", JSON.stringify([]));
  };

  useEffect(() => {
    const getData = async () => {
      const { locationId, tenantId } = locationInfo;
      const url = `${config.SophieApiUrl}/api/locationInfo?locationId=${locationId ?? ""}&tenantId=${tenantId}`;

      const dashboard = await fetch(url)
        .then((response) => {
          if (response.status === 200) return response.json();
          return null;
        })
        .then((data) => data && JSON.parse(data.guestDashboard));

      if (dashboard) {
        setDefaultWidgets(dashboard.widgets);
      }
    }

    if (!locationInfo) {
      setWidgets([]);
      setDefaultWidgets([]);
      return;
    }

    getData();
  }, [locationInfo]);

  useEffect(() => {
    if (!locationInfo) return;

    setCurrentUser(currentIdentity);
  }, [locationInfo, currentIdentity]);

  useEffect(() => {
    const getData = async () => {
      const { locationId, tenantId } = locationInfo;
      const userId = currentUser.id ?? currentUser.facePersonId;
      const url = `${config.SophieApiUrl}/api/UserProfiles/${userId}?locationId=${locationId ?? currentUser.defaultLocation}&tenantId=${tenantId}`;

      const visibleWidgets = await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data?.dashboard) {
            return data.dashboard.widgets
              .filter(widget => !JSON.parse(localStorage.getItem("collapsed")).some(item => JSON.stringify(item) === JSON.stringify(widget)))
          }
        });

      // Update help object
      const data = await fetch(`${config.SophieApiUrl}/api/WidgetSettings`)
       .then((response) => {
          if (response.status === 200) return response.json();
          return null;
        })

      if (data) {
        for (const visibleWidget of visibleWidgets) {
          const widget = data.filter(item => item.name.toLowerCase() == visibleWidget.name.toLowerCase())[0];

          visibleWidget.help = widget.help

          for (let i = 0; i < visibleWidget.help.description.length; i++) {
            visibleWidget.help.description[i] = visibleWidget.help.description[i].replace(/TITLE/g, visibleWidget.settings.heading || widget.name);
          }

          for (let i = 0; i < visibleWidget.help.steps.length; i++) {
            visibleWidget.help.steps[i] = visibleWidget.help.steps[i].replace(/TITLE/g, visibleWidget.settings.heading || widget.name);
          }
        }
      }

      setWidgets(visibleWidgets);
    }

    if (!currentUser || currentUser.id === "registeredVisitor") {
      setWidgets(defaultWidgets);
    } else {
      getData();
    }
  }, [currentUser, defaultWidgets, locationInfo]);

  const username = currentUser ? currentUser.name : "there";

  return (
    <div className=" flex flex-col gap-y-8 w-full z-10 pt-8 px-5">
      <WidgetList
        username={username}
        identity={currentUser}
        widgets={widgets}
      />
    </div>
  );
};

export default Dashboard;
