import React, { useState } from "react";

import { ReactComponent as DotsIcon } from "assets/images/dots.svg";
import { ReactComponent as DeleteIcon } from "assets/images/bin-ico.svg";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { TenantUser } from "models";
import { Avatar } from "components";

interface UserCardProps {
  user: TenantUser;
  deleteUser: (id) => void;
  editUser: (id) => void;
}

export const UserCard = (props: UserCardProps) => {
  const { user, deleteUser, editUser } = props;

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="relative px-4 py-3">
      <div className="UserCard py-3 pl-3">
        <Avatar url={user.avatarUrl} name={user.name} />
        <div
          className="leading-default font-normal text-black text-left flex-grow ml-3"
          onClick={() => setShowMenu(false)}
        >
          <p className="mb-2 text-sm font-bold">{user.name}</p>
          <p className="mb-1 text-xs">Email: {user.email}</p>
          <p className="text-xs">Username: {user.userName}</p>
        </div>
        <div className="mr-0 flex flex-col" onClick={() => setShowMenu(true)}>
          <DotsIcon className="mr-2 w-3 mb-5" />
        </div>
      </div>
      {showMenu && (
        <div className="absolute right-0 top-0 flex flex-col h-full py-3 mr-4">
          <button
            className="w-32 bg-white1 flex-grow border-solid border-b border-white0 items-center flex focus:outline-none"
            onClick={() => editUser(user.id)}
          >
            <EditIcon className="mx-2 w-5" />
            Edit
          </button>
          <button
            className="w-32 bg-white1 flex-grow items-center flex focus:outline-none"
            onClick={() => deleteUser(user.id)}
          >
            <DeleteIcon className="mx-2 w-5" /> Delete
          </button>
        </div>
      )}
    </div>
  );
};
