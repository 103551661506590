import { users, addDays } from './testDataUtility';

// My Team Widget
class User {
  name: string;
  project: string;
  location: string;
  estimatedArrival: Date;
  nextAvailable: Date;
  dailyScrum: Date;

  constructor(name, project, location, estimatedArrival, nextAvailable, dailyScrum) {
    this.name = name;
    this.project = project;
    this.location = location;
    this.estimatedArrival = estimatedArrival;
    this.nextAvailable = nextAvailable;
    this.dailyScrum = dailyScrum;
  }
}

export const myTeam = [
  new User(users[0], "Internal", "Melbourne", addDays(0, 12, 0), addDays(2, 14, 30), addDays(0, 9, 15)),
  new User(users[2], "Leave", "Brisbane", null, addDays(7, 0, 0), addDays(0, 11, 45)),
  new User(users[1], "Client", "Sydney", addDays(0, 9, 30), addDays(1, 12, 15), addDays(0, 9, 15)),
];

export const desensitisedMyTeam = (sensitiveTeamData) => {
  const desensitisedOfficeData = sensitiveTeamData.map((office) => {
    const data = office.data?.map((user) => ({
       ...user,
       location: office.location,
    }));
 
    return {
       ...office,
       data,
    }
  });

  return desensitisedOfficeData;
}
