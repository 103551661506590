import React, { useEffect, useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import NoContent from "../../NoContent/NoContent";

import config from "config";
import "./twitter.css";

interface TwitterProps {
  userHandle: string;
  count: number;
}

interface Tweet {
  date: Date;
  id: string;
  text: string;
  url: string;
}

export const TwitterWidget = ({ userHandle, count }: TwitterProps) => {
  const [tweets, setTweets] = useState<Tweet[]>([]);

  useEffect(() => {
    fetch(
      `${config.SophieApiUrl}/api/twitter?userHandle=${userHandle}&count=${count}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTweets(data);
      });
  }, [userHandle, count]);

  return (
    <div className="container-fluid">
      { tweets.length > 0 ?
        <div className="twitter-widget flex flex-wrap overflow-auto pr-4">
          {tweets.map((t) => {
            return (
              <div key={t.id} className="sm:w-full md:w-1/2 w-1/3 px-2">
                <TwitterTweetEmbed
                  key={t.id}
                  tweetId={t.id}
                  options={{
                    width: "100%",
                  }}
                  />
              </div>
            );
          })}
        </div>
        :
        <NoContent>
          <span>No tweets found for user @{userHandle}</span>
        </NoContent>
      }
    </div>
  );
};
