import React from 'react';
import preval from 'preval.macro';
import moment from 'moment';
import './Footer.css';
import config from '../../config';

const buildTimestamp = preval`module.exports = new Date().getTime();`;

const Footer = () => {
  return (
    <footer className="py-4 md:py-4 mt-6 lg:py-2 text-left text-base text-center bg-footer">
      <section className="main-container">
        <div className="xl:mx-6">
          <div className="flex flex-col md:flex-row justify-between mx-6">
            <div className="py-2">
              Our website is under{' '}
              <a
                className="footer-link"
                href="https://rules.ssw.com.au/WebSites/RulestoBetterWebsites-Deployment/Pages/Do-your-developers-deploy-manually.aspx"
              >
                CONSTANT CONTINUOUS DEPLOYMENT
              </a>
              . Last deployed {getLastDeployTime()} ago (Build #{' '}
              {config.BuildVersion})
            </div>
            <div className="py-2">
              Copyright © SSW 1990 - {new Date().getFullYear()}. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

const getLastDeployTime = () => {
  const lastDeployDuration = moment.duration(Date.now() - buildTimestamp).asMilliseconds();
  let delta = Math.abs(lastDeployDuration) / 1000;

  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  return days !== 0
    ? `${days} day(s)`
    : ' ' + (hours !== 0
    ? `${hours} hour(s)`
    : ' ' + (minutes > 1
    ? `${minutes} minutes`
    : '1 minute'));
};

export default Footer;
