import { ReactComponent as BinIcon } from "assets/images/bin-ico.svg";
import Modal from "components/Modal/Modal";
import { useMemo, useState } from "react";

interface RemoveRecognitionProps {
  disabled: boolean;
  remove: () => Promise<boolean>;
}

const RemoveRecognition = ({ disabled, remove }: RemoveRecognitionProps) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<'success' | 'failure' | null>();
  const [message, setMessage] = useState<string>();

  const handleOpen = () => {
    setStatus(null);
    setMessage(null);
    setOpen(true);
  }

  const handleRemove = () => {
    setOpen(false);
    setMessage('Removing...');

    remove().then((success) => {
      if (success) {
        setStatus('success');
        setMessage('Success!');
      } else {
        setStatus('failure');
        setMessage('Remove failed!');
      }
    })
  }

  const messageClassName = useMemo(() => ['input-message'].concat(status ? [`input-message-${status}`] : []).join(' '), [status]);

  return (
    <div className="flex">
      <button
        className="facial-recognition-button flex items-center text-xl"
        type="button"
        disabled={disabled}
        onClick={handleOpen}
      >
        <BinIcon className="h-6 mr-2" />
        Remove facial recognition*
      </button>
      <div className={messageClassName}>{message}</div>
      {open && (
        <Modal>
          <div className="flex flex-col p-4 bg-white1 rounded">
            <div className="text-xl">
              Do you want to remove all faces uploaded?
            </div>
            <div className="flex justify-center gap-x-8 mt-4">
              <button type="button" className="facial-recognition-button DarkBtn text-xl" onClick={handleRemove}>Confirm</button>
              <button type="button" className="facial-recognition-button DarkBtn text-xl" onClick={() => setOpen(false)}>Cancel</button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default RemoveRecognition;