import React, { useState, useEffect } from "react";

import { WeatherInfo } from "models";
import CurrentWeatherInfo from "./CurrentWeather";
import WeatherForecast from "./WeatherForecast";
import NoContent from "../../NoContent/NoContent";

import config from "config";
import "./weather.css";
import { refreshData } from "services/Helpers.service";

interface WeatherProps {
  city: string;
  country: string;
}
export const WeatherWidget = ({ city, country }: WeatherProps) => {
  const [allWeather, setAllWeather] = useState<WeatherInfo[]>([]);
  const [currentWeather, setCurrentWeather] = useState<WeatherInfo>(null);

  useEffect(() => {
    if (city && country) {
      fetchWeatherData(city, country);
      refreshData(() => fetchWeatherData(city, country));
    }
  }, [city, country]);

  const fetchWeatherData = (city: string, country: string) => {
    fetch(
      `${config.SophieApiUrl}/api/WeatherInfo?city=${city}&country=${country}`
    )
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.date - b.date);
        setAllWeather(data);
        setCurrentWeather(data[0]);
      });
  };

  return (
    <div className="weather-widget container-fluid">
      { (currentWeather && allWeather) ?
        <div className="flex flex-wrap">
          <div className="lg:w-1/3 w-1/4 py-2">
            {currentWeather && (
              <CurrentWeatherInfo
                weather={currentWeather}
                location={currentWeather.location}
                />
            )}
          </div>
          <div className="lg:w-2/3 w-3/4 py-2">
            {allWeather && <WeatherForecast days={allWeather} />}
          </div>
        </div>
        :
        <NoContent>
          <div className="flex flex-col">
            <span>No weather data available.</span>
          </div>
        </NoContent>
      }
    </div>
  );
};
