import { ReactNode } from "react";
import "./userSection.css";

interface UserSectionProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  children: ReactNode;
}

const UserSection = ({ Icon, title, children }: UserSectionProps) => {
  return (
    <div>
      <div className="user-section_header">
        <Icon className="h-8 mr-6" />
        <h1 className="text-2xl">{title}</h1>
      </div>
      <div className="user-section_content">
        {children}
      </div>
    </div>
  )
};

export default UserSection;
